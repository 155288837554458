<script lang="ts" setup>
import { computed, ref, useAttrs, watch } from 'vue'

const props = defineProps({
  number: {
    type: [Number, String],
    required: true,
  },
  h: {
    type: Number, // 数字高度
    default: 22,
  },
  color: {
    type: [String],
    default: '#606cee',
  },
  customStyle: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const attrs = useAttrs()
// 绑定自定义属性，包括class
const getBindValue = computed(() => {
  return {
    ...attrs,
  }
})

const numberBox = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '.', '-', ':', ' ']
const ignoreStr = ['.', ' ', '-', ':']
const numberToArray = ref<any[]>([])

watch(
  () => props.number,
  (n: any) => {
    numberToArray.value = n.toString().split('')
  },
  {
    immediate: true,
    deep: true,
  },
)

const numStyle = computed(() => {
  return {
    ...props.customStyle,
    height: `${props.h}px`,
    fontSize: `${props.h}px`,
  }
})

function animateStyle(item: any) {
  // eslint-disable-next-line eqeqeq
  const index = item.trim().length === 0 ? 9999 : numberBox.findIndex(i => item == i)
  return { transform: `translate(0,-${index * props.h}px)` }
}
</script>

<template>
  <div style="display: flex; align-items: center">
    <div
      v-for="(item, index) in numberToArray" :key="index" class="number" :class="[{ sign: ignoreStr.includes(item) }]"
      :style="numStyle"
    >
      <div class="scroll-container" :style="{ ...animateStyle(item) }" v-bind="getBindValue">
        <div
          v-for="(v, i) in numberBox" :key="i" class="scroll-single" :style="{ color }"
        >
          {{ v }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.number {
  overflow: hidden;
  font-size: 22px!important;
  width: 13px;
}
.sign {
  width: 7px;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  transform: translate(0, 0);
  transition: all 1s;
}

.scroll-single {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 1;
  overflow: hidden;
  font-weight: bold;
}
</style>
