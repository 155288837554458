<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useTitle } from '@vueuse/core'
import { api } from '@/api'
import { useLoadingStore, useToastStore } from '@/stores'
import { $formatErrorMessage } from '@/helpers'

const route = useRoute()
const { $toast } = useToastStore()
const { $showLoading, $hideLoading } = useLoadingStore()

const preview = ref<GetActivityGetcontentimagelinkinfoResponse['data']>()
const title = useTitle('加载中...')

onMounted(async () => {
  $showLoading()
  try {
    const promotionId = route.query.promotionId ?? ''
    const data = await api.get<any, GetActivityGetcontentimagelinkinfoResponse>('/activity/getContentImageLinkInfo', { params: { promotionId } })
    preview.value = data.data
    title.value = preview.value?.appName || preview.value!.title
  }
  catch (error) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
})
</script>

<template>
  <div v-if="preview" class="w-screen h-min-screen bg-white">
    <img v-if="preview.banner" :src="preview.banner">
    <div class="px-20px">
      <p class="mt-20px text-30px font-bold">
        {{ preview.title }}
      </p>
      <div class="mt-20px relative">
        <img v-if="preview.chapterTemplate" class="w-full" :src="preview.chapterTemplate">
        <p class="absolute top-1/2 -translate-y-1/2 left-0 px-4 text-white text-19px font-bold z-10">
          第{{ preview.sequenceNo }}章
        </p>
      </div>
      <div class="mt-20px flex flex-col">
        <img v-for="image in preview.images" :key="image" :src="image">
      </div>
      <div class="relative">
        <img v-if="preview.shareTemplate" :src="preview.shareTemplate">
        <img v-if="preview.shareImage" :src="preview.shareImage" class="w-20% h-36% absolute right-13% top-26%">
      </div>
    </div>
    <img v-if="preview.guidanceTemplate" class="mt-20px" :src="preview.guidanceTemplate">
    <div class="h-10" />
  </div>
</template>
