<script setup lang="ts">
import { PAY_LOCATION_PAGE, PRODUCT_TYPE, STORAGE_SIGN_PRODUCT_ID } from '@/constants'
import { fen2yuan } from '@/utils'
import { useConfigStore, useLoadingStore, useQueryStore, useTrackValueStore, useUserStore } from '@/stores'
import { $getAppId, $gotoPayProduct } from '@/helpers'
import { api } from '@/api'

type Product = GetRechargetemplateGetproductlistResponse['data'][0]

const props = withDefaults(
  defineProps<{
    product: Product
    highlight?: boolean
    plain?: boolean
    payLocation?: PAY_LOCATION_PAGE
    contentId?: string
    contentName?: string
    chapterId?: string
    chapterIndex?: number
    skipSign?: boolean
  }>(),
  {
    highlight: false, // 高亮
    plain: false, // 简单样式去掉手势和角标
    payLocation: PAY_LOCATION_PAGE.NONE,
    skipSign: false, // 不需要签约, 直接去支付商品
  },
)

const userStore = useUserStore()
const configStore = useConfigStore()
const queryStore = useQueryStore()
const trackValueStore = useTrackValueStore()
const { $showLoading, $hideLoading } = useLoadingStore()

async function onSign() {
  window.collectEvent('pagepurClick', {
    page: 'VIP',
    button: 'SVIP 商品',
    money: fen2yuan(props.product.price),
  })

  // Note: 如果是章节付费弹窗添加 `skipPosition`
  let redirect = window.location.href
  if (props.payLocation === PAY_LOCATION_PAGE.CHAPTER && !window.location.href.includes('skipPosition'))
    redirect = `${window.location.href}&skipPosition=1`

  // 如果不需要签约, 直接去支付商品
  if (props.skipSign) {
    const productId = props.product.id || props.product.productId
    $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: props.payLocation, redirect })
    return
  }

  try {
    $showLoading()
    // Note: check exist sign subscribe?
    const { data: exist } = await api.post('/pay/getContractInfo', {
      productId: props.product.id,
    })

    // 如果存在合约, 直接去支付商品
    if (exist) {
      const productId = props.product.id
      $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: props.payLocation, redirect })
      return
    }
    const payload = {
      appId: $getAppId(),
      memberId: userStore.$state.memberId,
      productId: props.product.id,
    }
    window.localStorage.setItem(STORAGE_SIGN_PRODUCT_ID, props.product.id)
    const { data } = await api.post<any, PostPayCreatewechatmpcontractResponse>('/pay/createWechatMPContract', payload)
    const subscribeUrl = data?.returnUrl as string
    window.location.href = subscribeUrl
  }
  finally {
    $hideLoading()
  }
}
</script>

<template>
  <div
    class="w-130px h-155px px-10px pt-30px text-center rounded-2 relative"
    :class="highlight
      ? 'bg-gradient-to-b from-#FFF6EB to-#FBDFB9 border-2px border-#EB514C'
      : 'w-120px! bg-#F6F6F6 border-1px border-#0000001A'"
    @click="onSign"
  >
    <p class="text-15px font-bold">
      {{ product.name }}
    </p>
    <p class="text-#794902 font-bold flex items-baseline justify-center">
      <span class="text-13px">￥</span>
      <span class="text-35px">{{ fen2yuan(product.price) }}</span>
    </p>
    <p class="-mt-5px text-#9A9A9A text-12px" :class="{ 'line-through': product.productType === PRODUCT_TYPE.SVIP }">
      <span>{{ product.description }}</span>
    </p>
    <template v-if="!plain">
      <!-- finger -->
      <img
        v-if="product.pointerState" class="w-50px absolute bottom-4 -right-5 z-10"
        src="@/assets/icon-flash-finger.gif" alt="图标:手指"
      >
      <!-- left corner -->
      <p
        v-if="product.cornerTip"
        class="absolute  -top-1.5 px-2 py-1 text-white text-11px rounded-tl-2 rounded-br-2 z-1"
        :class="highlight
          ? '-left-2px bg-gradient-to-r from-#EB5C4C to-#FE5D5C'
          : '-left-1px bg-gradient-to-r from-#D38B2B to-#F1CA97'"
      >
        {{ product.cornerTip }}
      </p>
      <!-- footer -->
      <p
        v-if="product.footTip"
        class="absolute w-130px h-25px lh-25px text-11px font-bold rounded-b-2"
        :class="highlight
          ? 'text-white bg-#EB514C -left-2px -bottom-2px'
          : 'w-120px! bg-#FDEAD1 text-#794902 -left-1px -bottom-1px'"
      >
        {{ product.footTip }}
      </p>
    </template>
  </div>
</template>
