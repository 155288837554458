<script lang="ts" setup>
import { useScroll } from '@vueuse/core'
import { computed, inject } from 'vue'
import { useGotoBack } from '@/composables'

withDefaults(
  defineProps<{
    opacity?: boolean
    scale?: boolean
    title?: string
  }>(),
  {
    opacity: false,
    scale: false,
  },
)

const gotoBack = useGotoBack()

const containerRef = inject<HTMLElement>('containerRef', document.body)
const theme = inject<{ background: string }>('theme', { background: 'white' })

const { y } = useScroll(containerRef)
const max = 300
const opacityStyle = computed(() => {
  const opacity = Math.min(y.value / max, 1)
  return `background-color: rgba(255, 255, 255, ${opacity});`
})
</script>

<template>
  <div
    class="w-full fixed top-0 flex items-center z-10"
    :style="(opacity ? opacityStyle : '') + (y !== 0 ? `background: ${theme.background}` : '')"
  >
    <div class="p-2" @click="gotoBack()">
      <i-icon-park-outline-left
        class="transition-all duration-200"
        :class="(scale && y !== 0) ? 'w-4 h-4 text-#999' : 'w-7 h-7'"
      />
    </div>
    <slot>
      <p v-show="title && scale && y !== 0" class="text-#999">
        {{ title }}
      </p>
    </slot>
  </div>
</template>
