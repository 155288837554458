<script lang="ts" setup>
import { defineComponent } from 'vue'
</script>

<script lang="ts">
export default defineComponent({
  name: 'NotFound',
})
</script>

<template>
  <div class="py-20 w-50 mx-a">
    <img class="w-full" src="@/assets/cover-empty.png">
    <p class="mt-14px text-center text-#666 text-13px">
      页面不存在
    </p>

    <div class="flex justify-center">
      <router-link :to="{ name: 'home' }" replace>
        <button class="mt-15 px-4 py-2 text-primary border-primary border-1 border-solid rounded-full">
          去首页
        </button>
      </router-link>
    </div>
  </div>
</template>
