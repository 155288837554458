<!-- TODO: Refactor to slot data -->
<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'

const props = withDefaults(
  defineProps<{
    seconds: number
    theme?: 'primary' | 'transparent'
  }>(),
  {
    theme: 'primary',
  },
)

const emit = defineEmits<{
  (event: 'end'): void
}>()

const duration = ref(props.seconds)

watchEffect(() => {
  const intervalId = setInterval(() => {
    duration.value--
    if (duration.value <= 0) {
      clearInterval(intervalId)
      emit('end')
    }
  }, 1000)

  return () => clearInterval(intervalId)
})

const days = computed(() => {
  return Math.floor(duration.value / 86400).toString().padStart(2)
})
const hours = computed(() => {
  return Math.floor((duration.value % 86400) / 3600).toString().padStart(2, '0')
})
const minutes = computed(() => {
  return Math.floor((duration.value % 3600) / 60).toString().padStart(2, '0')
})
const seconds = computed(() => {
  return (duration.value % 60).toString().padStart(2, '0')
})
</script>

<template>
  <div class="countdown flex text-white text-12px text-center leading-21px" :class="{ 'text-20px lh-35px': theme === 'transparent' }">
    <template v-if="Number(days)">
      <span
        class="inline-block w-21px h-21px rounded"
        :class="{ 'bg-primary': theme === 'primary', 'bg-#FFFFFF66 w-35px h-35px': theme === 'transparent' }"
      >
        {{ days }}
      </span>
      <span
        class="inline-block px-0.5"
        :class="{ 'text-primary': theme === 'primary', 'text-white opacity-70 text-16px px-2': theme === 'transparent' }"
      >
        天
      </span>
    </template>
    <span
      class="inline-block w-21px h-21px rounded"
      :class="{ 'bg-primary': theme === 'primary', 'bg-#FFFFFF66 w-35px h-35px': theme === 'transparent' }"
    >
      {{ hours }}
    </span>
    <span
      class="inline-block w-6px"
      :class="{ 'text-primary': theme === 'primary', 'text-white opacity-70 w-auto px-1': theme === 'transparent' }"
    >
      :
    </span>
    <span
      class="inline-block w-21px h-21px rounded"
      :class="{ 'bg-primary': theme === 'primary', 'bg-#FFFFFF66 w-35px h-35px': theme === 'transparent' }"
    >
      {{ minutes }}
    </span>
    <span
      class="inline-block w-6px"
      :class="{ 'text-primary': theme === 'primary', 'text-white opacity-70 w-auto px-1': theme === 'transparent' }"
    >
      :
    </span>
    <span
      class="inline-block w-21px h-21px rounded"
      :class="{ 'bg-primary': theme === 'primary', 'bg-#FFFFFF66 w-35px h-35px': theme === 'transparent' }"
    >
      {{ seconds }}
    </span>
  </div>
</template>
