<script lang="ts" setup>
import '@unocss/reset/tailwind.css'

import { onMounted, provide, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useUserStore } from './stores'

const route = useRoute()
const userStore = useUserStore()

const bizModalRechargeInterception = ref()
provide('bizModalRechargeInterception', bizModalRechargeInterception)

const bizModalOperationalPosition = ref()
provide('bizModalOperationalPosition', bizModalOperationalPosition)

const loading = ref(true)
onMounted(() => {
  // eslint-disable-next-line no-console
  console.log('[App] => onMounted')

  loading.value = false
})
</script>

<template>
  <div id="app">
    <div v-if="loading">
      <div class="pt-20vh flex justify-center">
        <BasicSpinner class="mx-auto" />
      </div>
    </div>

    <div v-else>
      <RouterView />

      <!-- 公众号迁移公告 -->
      <BizPopupTransfer />

      <BasicLoading />
      <BasicToast class="z-1001" />

      <!-- <BizModalActivity v-if="userStore._fetched" /> -->
      <BizModalRechargeInterception v-if="userStore._fetched" ref="bizModalRechargeInterception" />
      <BizModalOperationalPosition v-if="userStore._fetched" ref="bizModalOperationalPosition" />

      <!-- Note: 等待授权完成 -->
      <template v-if="route.name !== 'authorize'">
        <!-- Modal: SOP 领取金币 -->
        <BizModalSopReward v-if="userStore._fetched" />
      </template>
    </div>

    <portal-target name="root" multiple />
  </div>
</template>

<style>
html, body {
  height: 100%;
  -webkit-overflow-scrolling: auto;
  overflow: hidden;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, SF UI Text, Helvetica Neue, PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
  touch-action: pan-y;
  font-size: 16px;
  max-width: var(--var-max-body-width, 750px);
  margin: 0 auto;
  background-color: white;
}
#app {
  height: 100%;
  margin: 0 auto;
  background: #F4F5F7;
  position: relative;
  overflow-y: scroll;
}

/** Some global transition  */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* slide-left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.4s ease;
}
.slide-left-enter, .slide-left-leave-to {
  transform: translateX(100%);
}
/* slide-right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.4s ease;
}
.slide-right-enter, .slide-right-leave-to {
  transform: translateX(-100%);
}

/** Some reset css */
p {
  margin: 0;
  overflow-wrap: anywhere;
}
div, input, textarea, button, select, a {
  -webkit-tap-highlight-color: transparent!important;
}
input:focus {
  outline: 0;
}
::-webkit-scrollbar {
  display: none;
}
</style>
