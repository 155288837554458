<script setup lang="ts">
import { useRouter } from 'vue-router/composables'

const router = useRouter()

function gotoCancelSubscribe() {
  router.replace({ name: 'cancelSubscribe' })
}
</script>

<template>
  <p class="flex items-center space-x-2 text-12px text-#00000099" @click="gotoCancelSubscribe">
    <span>到期自动付费，可随时取消</span>
    <i-assets-icon-question class="w-4 h-4" />
  </p>
</template>
