import axios from 'axios'
import { useQueryStore } from './stores'
import { $getToken, $uuid } from '@/helpers'
import { isIOS } from '@/constants'

import { useToastStore, useUserStore } from '@/stores'
import router from '@/router'

let apiDomain = import.meta.env.VITE_API_DOMAIN
export const api = axios.create({
  baseURL: apiDomain + import.meta.env.VITE_API_BASE,
  responseType: 'json',
})
export function updateApiDomain(domain: string) {
  apiDomain = domain
}

api.interceptors.request.use((options) => {
  // Skip `development` mode, Because of CORS
  if (import.meta.env.MODE !== 'development')
    options.baseURL = apiDomain + import.meta.env.VITE_API_BASE

  const token = $getToken()

  if (token)
    options.headers.Authorization = token

  const queryStore = useQueryStore()

  options.params = {
    ...options.params,
    max_sid: queryStore.$state.max_sid || '0',
    max_pid: queryStore.$state.max_pid || '0',
    max_app_id: queryStore.$state.max_app_id || '0',
    max_platform: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows
    max_os: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows
    max_version: __APP_VERSION__,
    website: 'mp_h5',
    uuid: $uuid(),
  }

  return options
})

api.interceptors.response.use((response) => {
  // 用户 TOKEN 过期
  if (response.data.code === 401) {
    useToastStore().$toast('用户信息过期，请重新登录')
    useUserStore().logout()
    const redirect = window.encodeURIComponent(router.currentRoute.fullPath)
    router.replace({
      name: 'home',
      query: {
        redirect,
      },
    })
    return
  }

  if (response.data.code !== 0)
    return Promise.reject(response.data)

  return response.data
})
