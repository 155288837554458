<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { useReadStore, useToastStore, useTrackValueStore } from '@/stores'
import { TRACK_CHANNEL_NAME_VALUE } from '@/constants'

type Book = GetReadHistoryListResponse['data']['records'][0]

const { $toast } = useToastStore()
const readStore = useReadStore()

const history = ref<Book[]>([])
const page = ref(1)
const total = ref<number>(0)
const fetching = ref(false)
const ending = ref(false)
const error = ref<any>()
const pagination = computed(() => ({
  pageIndex: page.value,
  pageSize: 20,
}))
async function fetchHistory() {
  fetching.value = true
  try {
    const { data } = await api.get<any, GetReadHistoryListResponse>('/read/history/list', { params: { ...pagination.value } })
    history.value = [...history.value, ...data.records]
    total.value = data.total
    if (page.value >= data.pages)
      ending.value = true
    else
      page.value += 1
  }
  catch (_error) {
    error.value = _error
  }
  finally {
    fetching.value = false
  }
}
const infiniteRef = ref<HTMLElement>()
useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchHistory()
  },
  { distance: 50 },
)

const route = useRoute()
const from = computed(() => {
  return route.query.from as string
})

const router = useRouter()

function gotoChapter(item: Book) {
  if (item.offlineFlag)
    return $toast('很抱歉，该书已下架')
  readStore.update({
    contentId: item.contentId,
    chapterId: item.contentChapterId,
  })
  const trackValueStore = useTrackValueStore()
  trackValueStore.set({ bookId: item.contentId, firstOrigin: 4, originName: 4, chlName: TRACK_CHANNEL_NAME_VALUE.READ_HISTORY })

  router.replace({
    name: 'chapter',
    params: {
      contentId: item.contentId,
      chapterId: item.contentChapterId,
    },
  })
}
</script>

<template>
  <div ref="infiniteRef" class="pb-20 h-screen overflow-scroll bg-white">
    <div v-if="from">
      <BasicNavbar />
      <div class="h-5" />
    </div>
    <!-- Fist Loading -->
    <div v-if="fetching && page === 1" />
    <div v-else-if="history.length === 0" class="mt-11 pt-15vh w-full text-center flex flex-col justify-center">
      <div>
        <img class="mx-a w-50" src="@/assets/cover-empty.png" alt="图片:空">
        <p class="mt-14px text-13px text-#666">
          这里有点空，赶紧去发现新小说吧！
        </p>
        <div class="mt-50px">
          <BizTrackClick name="去书城看看" type="link">
            <router-link
              :to="{ name: 'home' }" replace
              class="inline-block text-primary text-14px py-2 px-4 border border-solid border-primary rounded-full"
            >
              去书城看看
            </router-link>
          </BizTrackClick>
        </div>
      </div>
    </div>
    <div v-else class="p-5">
      <p class="text-14px text-#666 -mb-10px">
        共计 {{ total }} 本
      </p>
      <div v-for="item in history" :key="item.contentId">
        <BizTrackClick
          :name="item.contentName" type="link"
          :extra="{
            contentName: item.contentName,
            contentId: item.contentId,
            chapterId: item.chapterId,
          }"
        >
          <div
            class="mt-5 flex items-center space-x-15px"
            @click="gotoChapter(item)"
          >
            <div class="relative">
              <img class="w-18 h-25 rounded-3px" :src="item.contentCoverUrl" alt="图片:书封">
              <p v-if="item.offlineFlag" class="absolute inset-0 bg-#00000099 lh-25 text-13px text-white text-center rounded-3px">
                已下架
              </p>
            </div>
            <div>
              <p class="text-15px font-bold">
                {{ item.contentName }}
              </p>
              <p class="text-13px mt-5 text-#666">
                <span>{{ item.finishStatus ? '已完结' : '未完结' }}</span>
              </p>
              <p class="text-13px mt-5px text-#666">
                上次读到：{{ item.contentChapterName }}
              </p>
            </div>
          </div>
        </BizTrackClick>
      </div>

      <div v-if="ending" class="py-10 flex space-x-2.5 items-center justify-center">
        <span class="w-4 h-1px bg-#999" />
        <span class="text-14px text-#999">
          到底啦，去找下一本好书吧
        </span>
        <span class="w-4 h-1px bg-#999" />
      </div>
      <div v-else-if="fetching" class="py-15px flex items-center justify-center space-x-4 text-13px text-#999">
        <div class="animate-spin">
          <i-icon-park-outline-loading />
        </div>
        <p>加载中...</p>
      </div>
    </div>

    <BasicTabbar v-if="!from" tab="history" />
  </div>
</template>
