<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import { useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { useTrackValueStore } from '@/stores'
import { CONTENT_CHANNEL, TRACK_CHANNEL_NAME_VALUE } from '@/constants'

const props = withDefaults(
  defineProps<{
    channel?: 'mm' | ''
  }>(),
  {
    channel: '',
  },
)

const router = useRouter()
function onChangeChannel(value: '' | 'mm') {
  router.replace({ params: { channel: value } })
}

// Left categories
const categories = ref<any[]>([])
const categoryId = ref<string>('')
async function fetchCategories() {
  const { data } = await api.get<any, GetContentGetcontentclasslistResponse>('/content/getContentClassList', {
    params: {
      contentType: 1,
      contentChannel: props.channel === '' ? CONTENT_CHANNEL.MAN : CONTENT_CHANNEL.WOMAN,
    },
  })
  categories.value = data
  categoryId.value = data[0].id
}
onMounted(async () => {
  await fetchCategories()
})

// Right list
const books = ref<any[]>([])
const page = ref(1)
const fetching = ref(false)
const ending = ref(false)
const error = ref<any>()
const pagination = computed(() => ({
  pageIndex: page.value,
  pageSize: 20,
}))
async function fetchBooks() {
  fetching.value = true
  try {
    const { data } = await api.get<any, GetContentGetcontentclasscontentlistResponse>('/content/getContentClassContentList', {
      params: {
        contentType: 1,
        contentChannel: props.channel === '' ? CONTENT_CHANNEL.MAN : CONTENT_CHANNEL.WOMAN,
        contentClassId: categoryId.value,
        ...pagination.value,
      },
    })
    books.value = [...books.value, ...data.records]
    if (page.value >= data.pages)
      ending.value = true
    else
      page.value += 1
  }
  catch (_error) {
    error.value = _error
  }
  finally {
    fetching.value = false
  }
}
const infiniteRef = ref<HTMLElement>()
useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    if (!categoryId.value)
      return
    await fetchBooks()
  },
  { distance: 50 },
)

function onChangeCategory(id: string) {
  categoryId.value = id
}
watch(() => categoryId.value, () => {
  page.value = 1
  books.value = []
  fetchBooks()
})

const tabbarRef = ref()
const containerHeight = computed(() => {
  const $el = tabbarRef.value?.$el as HTMLDivElement
  if (!$el)
    return 0
  return window.innerHeight - $el.clientHeight - 44
})

// Watch route `channel` change
watch(() => props.channel, async () => {
  fetching.value = false
  ending.value = false
  page.value = 1
  await Promise.all([
    fetchCategories(),
    fetchBooks(),
  ])
})

function onTrackGotoBook(bookId: string) {
  const trackValueStore = useTrackValueStore()
  trackValueStore.set({ bookId, firstOrigin: 7, originName: 7, chlName: TRACK_CHANNEL_NAME_VALUE.CATEGORY })
  return true
}
</script>

<template>
  <div>
    <div class="h-11 w-full flex justify-center space-x-8 bg-white border-b border-#0000001A">
      <BizTrackClick name="男生" type="tab">
        <p class="lh-10 relative" @click="onChangeChannel('')">
          <span :class="{ 'text-primary font-bold': channel === '' }">男生</span>
          <span v-show="channel === ''" class="block mx-a w-6 h-0.5 mt-2px bg-primary rounded" />
        </p>
      </BizTrackClick>
      <BizTrackClick name="女生" type="tab">
        <p class="lh-10 relative" @click="onChangeChannel('mm')">
          <span :class="{ 'text-primary font-bold': channel === 'mm' }">女生</span>
          <span v-show="channel === 'mm'" class="block mx-a w-6 h-0.5 mt-2px bg-primary rounded" />
        </p>
      </BizTrackClick>
    </div>

    <div class="flex">
      <div ref="infiniteRef" class="w-20 h-full overflow-scroll" :style="`height: ${containerHeight}px;`">
        <BizTrackClick
          v-for="item in categories" :key="item.id"
          :name="item.name" type="button"
        >
          <p
            class="lh-55px text-13px text-center" :class="{ 'bg-white text-primary font-bold': categoryId === item.id }"
            @click="onChangeCategory(item.id)"
          >
            {{ item.name }}
          </p>
        </BizTrackClick>
      </div>

      <div class="flex-1 p-15px bg-white overflow-scroll" :style="`height: ${containerHeight}px;`">
        <BizTrackClick v-for="(item, index) in books" :key="index" :name="item.contentName" type="link">
          <router-link
            v-slot="{ navigate }" :to="{ name: 'book', params: { contentId: item.id } }"
            replace custom
          >
            <div class="flex space-x-10px mb-5" @click="onTrackGotoBook(item.id) && navigate($event)">
              <img class="w-55px h-77px rounded" :src="item.contentCoverUrl" alt="图片:书封">
              <div class="flex flex-1 flex-col justify-center overflow-hidden">
                <p class="truncate text-15px font-bold">
                  {{ item.contentName }}
                </p>
                <p class="mt-2 text-13px text-#666 line-clamp-2">
                  {{ item.introduce }}
                </p>
              </div>
            </div>
          </router-link>
        </BizTrackClick>
      </div>
    </div>

    <BasicTabbar ref="tabbarRef" tab="category" />
  </div>
</template>
