<script lang="ts" setup>
import queryString from 'query-string'
import { isPast } from 'date-fns'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useEventBus, useStorage } from '@vueuse/core'
import BasicModal from './BasicModal.vue'
import { useConfigStore, useQueryStore, useRechargeInterceptionStore, useToastStore, useTrackValueStore, useUserStore } from '@/stores'
import { trackRealtime } from '@/track'
import { fen2yuan } from '@/utils'
import { $getToken } from '@/helpers'
import { generateOAuthUrl } from '@/wechat'
import { PAY_LOCATION_PAGE, PRODUCT_TYPE, STORAGE_RECHARGE_INTERCEPTION, isIOS } from '@/constants'

const model = ref(false)

const route = useRoute()
const router = useRouter()
const { $toast } = useToastStore()

const rechargeInterceptionStore = useRechargeInterceptionStore()
const rechargeInterception = useStorage<any>(STORAGE_RECHARGE_INTERCEPTION, { _exist: false })
onMounted(() => {
  // Note: remove when expired
  if (isPast(rechargeInterception.value?.expiredAt))
    rechargeInterception.value = { _exist: false }
})

const eventBus = useEventBus<string>('modal:rechargeInterception')
eventBus.on(async (event: string) => {
  // 充值拦截
  if (event === 'open') {
    if (rechargeInterception.value?._exist)
      return
    await rechargeInterceptionStore.init()
    if (rechargeInterceptionStore.$state._exist) {
      model.value = true

      trackRealtime('pageView',
        {
          activityId: rechargeInterceptionStore.rechargeBlockingActivityId,
          type: 'rechargeOaa',
          oaaType: 1, // 1:充值拦截;2:大转盘;3:折扣活动
          productId: rechargeInterceptionStore.productId,
          prizeId: rechargeInterceptionStore.prizeId,
          userToken: $getToken(),
        },
        {
          sourceId: rechargeInterceptionStore.rechargeBlockingActivityId,
          sourceType: 9,
        },
      )
    }
  }
  if (event === 'close')
    model.value = false
})

async function onReceive() {
  model.value = false

  // Track 点击
  trackRealtime('activityReceived',
    {
      activityId: rechargeInterceptionStore.rechargeBlockingActivityId,
      type: 'rechargeOaa',
      oaaType: 1, // 充值拦截
      productId: rechargeInterceptionStore.productId,
      prizeId: rechargeInterceptionStore.prizeId,
      userToken: $getToken(),
    },
    {
      sourceId: rechargeInterceptionStore.rechargeBlockingActivityId,
      sourceType: 9,
    },
  )

  // Note: 如果是固定金额直接跳转充值
  if (rechargeInterceptionStore.interceptType === 2) {
    const { productId } = rechargeInterceptionStore
    const configStore = useConfigStore()
    const userStore = useUserStore()
    const queryStore = useQueryStore()
    const trackValueStore = useTrackValueStore()
    await configStore.init({ force: true })
    const payDomain = configStore.$state.payDomain
    const appId = configStore.$state.payAppId
    if (!payDomain || !appId) {
      $toast('支付配置错误，请联系客服')
      return
    }
    const oAuthUrl = generateOAuthUrl({
      appId,
      redirect: `${payDomain}/paying?${queryString.stringify({
        redirect: window.encodeURIComponent(window.location.href),
        callbackUrl: window.encodeURIComponent(window.location.href),
        productId,
        memberId: userStore.$state.memberId,
        appId: queryStore.$state.max_app_id,
        promotionId: queryStore.$state.max_pid,
        paySid: queryStore.$state.max_sid,
        appVersion: __APP_VERSION__,
        OS: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows,
        payLocation: PAY_LOCATION_PAGE.ACTIVITY,
        contentId: '',
        chapterId: '',
        rechargeOaaId: rechargeInterceptionStore.rechargeBlockingActivityId ?? '',
        skipRechargeInterception: 1,
        ...queryStore.$state,
        ...trackValueStore.$state,
      })}`,
    })
    window.location.replace(oAuthUrl)
    return
  }

  rechargeInterception.value = { ...rechargeInterceptionStore.$state, expiredAt: Date.now() + rechargeInterceptionStore.$state.countDown * 1000 }

  $toast('红包领取红包')

  router.replace({
    name: 'pay',
  })
}

defineExpose({
  model,
})

// FIXME: 保留一位小数
function keepOneDigit(num: number) {
  let str = num.toFixed(2).toString().slice(0, -1)
  if (str.endsWith('.0'))
    str = str.slice(0, -2)
  return str
}

// 埋点
watch(() => model.value, () => {
  if (!model.value)
    return
  window.collectEvent('elementShow', {
    type: '充值拦截弹窗',
    page: route.meta?.title || '',
    activity_name: rechargeInterceptionStore.rechargeBlockingActivityName,
    activity_id: rechargeInterceptionStore.productId,
    goods_name: rechargeInterceptionStore.interceptType === 1 ? rechargeInterceptionStore.prizeName : rechargeInterceptionStore.productName,
    intercept_type: rechargeInterceptionStore.style,
  })
})

const imageLoaded = ref(false)
function onLoadImage() {
  imageLoaded.value = true
}
</script>

<template>
  <BasicModal v-model="model" :transparent="true">
    <div v-if="model" class="relative" @click="onReceive">
      <img class="w-full" :src="rechargeInterceptionStore.rechargeBlockingActivityImgUrl" @load="onLoadImage">
      <div v-if="!imageLoaded" class="flex justify-center items-center">
        <BasicSpinner />
      </div>
      <template v-else>
        <!-- VIP -->
        <div v-if="rechargeInterceptionStore.productType === PRODUCT_TYPE.VIP">
          <div class="w-full pr-12 font-bold flex flex items-end items-center justify-center absolute top-8%">
            <p class="text--white-colorful flex items-baseline space-x-1">
              <span class="text-80px lh-1em">{{ fen2yuan(rechargeInterceptionStore.redPacketAmount) }}</span>
            </p>
            <div class="flex flex-col ml-2">
              <span class="inline-block mt-2 text-#FF002C w-max text-15px px-2 rounded-full bg-#FED560">
                {{ rechargeInterceptionStore.cornerTip }}
              </span>
              <p class="mt-2 text--white-colorful">
                {{ rechargeInterceptionStore.footTip }}
              </p>
            </div>
          </div>
          <img
            v-if="rechargeInterceptionStore.pointerState === 1" src="@/assets/icon-vip-flash-finger.gif" alt="图标:手指:满减"
            class="w-50px absolute bottom-0 left-60% z-10"
          >
        </div>
        <div v-else-if="rechargeInterceptionStore.style === 1 || rechargeInterceptionStore.style === 11" class="w-full h-full absolute inset-0">
          <!-- 1: 专属满减礼包 2: 专属固定礼包 -->
          <div v-if="rechargeInterceptionStore.interceptType === 2" class="w-full font-bold flex flex-col items-end items-center absolute top-16%">
            <p class="text--colorful font-bold text-xs text-center font-bold w-full" />
            <div class="mt-17%">
              <span class="text--colorful text-20 lh-1em">{{ fen2yuan(rechargeInterceptionStore.redPacketAmount) }}</span>
              <span class="ml-1 text--colorful text-xl relative -top-1">元</span>
            </div>
            <img
              v-if="rechargeInterceptionStore.pointerState === 1" src="@/assets/icon-flash-finger.gif" alt="图标:手指:固定"
              class="w-50px absolute -bottom-35 left-1/2 z-10"
            >
          </div>
          <div v-else class="w-full flex flex-col items-end items-center absolute top-35%">
            <div class="w-full flex items-center text-#FF0705 px-10% space-x-5">
              <div class="flex items-baseline font-bold justify-center w-34% pl-1.5">
                <span class="text-30px lh-1em">{{ fen2yuan(rechargeInterceptionStore.redPacketAmount) }}</span>
                <span class="text-15px relative">元</span>
              </div>
              <div class="flex-1 justify-center items-center">
                <p class="text-#00000099 font-bold truncate">
                  {{ rechargeInterceptionStore.cname }}
                </p>
                <p class="text-#00000066 text-12px truncate">
                  满{{ fen2yuan(rechargeInterceptionStore.minRechargeAmount) }}可用，仅限今日
                </p>
              </div>
            </div>
            <img
              v-if="rechargeInterceptionStore.pointerState === 1" src="@/assets/icon-flash-finger.gif" alt="图标:手指:满减"
              class="w-50px absolute -bottom-28 left-1/2 z-10"
            >
          </div>
        </div>
        <div v-else-if="rechargeInterceptionStore.style === 2" class="w-full h-full absolute inset-0">
          <div class="text--colorful w-full font-bold flex justify-center absolute top-62% space-x-4 px-12%">
            <p class="flex-1 flex items-baseline justify-center font-bold">
              <span class="text-style2 text--colorful text-22px">{{ rechargeInterceptionStore.coinsBuy }} </span>
              <span class="text-style2">金币</span>
            </p>
            <p class="flex-1 flex items-baseline justify-center font-bold">
              <span class="text-style2 text--colorful text-22px">{{ rechargeInterceptionStore.coinsBonus }} </span>
              <span class="text-style2">金币</span>
            </p>
          </div>
          <img
            v-if="rechargeInterceptionStore.pointerState === 1" src="@/assets/icon-flash-finger.gif" alt="图标:手指:style2"
            class="w-50px absolute bottom-0 left-60% z-10"
          >
        </div>
        <div v-else-if="rechargeInterceptionStore.style === 3" class="w-full h-full absolute inset-0">
          <div class="w-full font-bold flex flex-col items-center absolute top-37% px-5">
            <div class="mt-5px flex items-baseline text-#FF002C space-x-1">
              <p class="text-40px">
                {{ rechargeInterceptionStore.coinsBuy + rechargeInterceptionStore.coinsBonus }}
              </p>
              <p>金币</p>
            </div>
            <div class="-mt-2 flex justify-center items-baseline space-x-1">
              <p class="text-#950000 text-25px">
                ￥{{ fen2yuan(rechargeInterceptionStore.redPacketAmount) }}
              </p>
              <p class="ml-2 text-#979797 text-14px line-through font-normal">
                原价￥{{ keepOneDigit((rechargeInterceptionStore.coinsBuy + rechargeInterceptionStore.coinsBonus) / rechargeInterceptionStore.exchangeAmount) }}
              </p>
            </div>
            <div v-if="rechargeInterceptionStore.footTip" class="mt-5% flex flex-col items-center relative">
              <img class="w-130px" src="@/assets/bubble-activity.png">
              <p class="text-center text-white text-truncate font-bold absolute top-2px">
                {{ rechargeInterceptionStore.footTip }}
              </p>
            </div>
          </div>
          <img
            v-if="rechargeInterceptionStore.pointerState === 1" src="@/assets/icon-flash-finger.gif" alt="图标:手指:style3"
            class="w-50px absolute bottom-0 left-60% z-10"
          >
        </div>
        <div v-else-if="rechargeInterceptionStore.style === 4" class="w-full h-full absolute inset-0">
          <div class="text--colorful w-full font-bold flex items-end justify-center whitespace-nowrap absolute top-32%">
            <p class="text-22px">
              充
            </p>
            <p class="text-59px lh-1em">
              {{ keepOneDigit(fen2yuan(rechargeInterceptionStore.redPacketAmount)) }}
            </p>
            <img class="w-32px h-32px -mx-8px relative bottom-10%" src="@/assets/icon-give.png">
            <p class="text-59px lh-1em">
              {{ Math.floor(rechargeInterceptionStore.coinsBonus / rechargeInterceptionStore.exchangeAmount) }}
            </p>
          </div>
          <img
            v-if="rechargeInterceptionStore.pointerState === 1" src="@/assets/icon-flash-finger.gif" alt="图标:手指:style4"
            class="w-50px absolute bottom-18 left-60% z-10"
          >
        </div>
      </template>
    </div>
  </BasicModal>
</template>

<style scoped>
.text--colorful {
  @apply bg-gradient-to-b from-#FFF8BB to-#FFB803 text-transparent bg-clip-text
}
.text--white-colorful {
  @apply bg-gradient-to-b from-#FEFDFD to-#FDE8C2 text-transparent bg-clip-text
}

.text-style2 {
  text-shadow: 1.5px 2px 0px #B80000;
  color: #FFE37A!important;
}
</style>
