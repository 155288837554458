import { useRouter } from 'vue-router/composables'
import { useHistoryStore } from './stores'

/**
 * @description Go back to the previous page, fallback to `home` page
 */
export function useGotoBack() {
  const router = useRouter()
  const historyStore = useHistoryStore()

  function gotoBack(fallback = 'home') {
    const url = historyStore.$pop()
    if (url)
      router.replace(url)
    else
      router.replace({ name: fallback })
  }
  return gotoBack
}
