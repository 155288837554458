<script lang="ts" setup>
import Swiper from 'swiper'
import 'swiper/css'
import { onMounted, ref, watch } from 'vue'
import { isAndroid } from '@/constants'

const props = withDefaults(
  defineProps<{
    enabled?: boolean
  }>(), {
    enabled: true,
  },
)

const emit = defineEmits<{
  (event: 'pulled'): void
}>()

let swiper: Swiper | null = null

defineExpose({
  reset: () => swiper?.slideTo(0),
})

onMounted(() => {
  swiper = new Swiper('.page.swiper', {
    enabled: props.enabled,
    direction: 'vertical',
    slidesPerView: 1,
    autoHeight: false,
    longSwipes: false,
    longSwipesMs: 300,
    speed: 300,
    touchReleaseOnEdges: true,
    freeMode: true,
    on: {
      transitionEnd() {
        emit('pulled')
      },
    },
  })
})

// Note: 如果是 Android, 特殊处理, 滑动动画会有问题, 所以手动触发下
let touchStartY = 0
let touchMoveY = 0
const translateY = ref(0)
function onTouchStart(event: TouchEvent) {
  touchStartY = event.touches[0].clientY
}
function onTouchMove(event: TouchEvent) {
  touchMoveY = event.touches[0].clientY
  translateY.value = touchMoveY - touchStartY
}
function onTouchEnd(event: TouchEvent) {
  if (!isAndroid)
    return
  if (props.enabled && translateY.value < 0) {
    swiper?.slideTo(1)
    emit('pulled')
  }
}

watch(() => props.enabled, (enabled) => {
  if (enabled)
    swiper?.enable()
  else
    swiper?.disable()
}, { immediate: true })
</script>

<template>
  <div class="page swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
        <slot />
      </div>
      <div class="swiper-slide footer">
        <slot name="footer">
          <div>本书已完结, 下拉阅读其他书籍</div>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper {
  height: 100vh;
}
.footer {
  min-height: 40px;
  height: auto;
  max-height: 50vh;
}
</style>
