<script lang="ts" setup>
import { format, isAfter, parseISO } from 'date-fns'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useConfigStore, useToastStore, useUserStore } from '@/stores'

const userStore = useUserStore()
const configStore = useConfigStore()
const appVersion = __APP_VERSION__

const { $toast } = useToastStore()
const router = useRouter()
const tapTimes = ref(0)
// Note: 调试入口, 1秒内连续点击击 5 次, 进入

let timerId: any
function onDebugEnter() {
  tapTimes.value++
  if (tapTimes.value === 5) {
    tapTimes.value = 0
    router.replace({ name: 'debug' })
    $toast('进入调试页面')
  }
  if (timerId)
    return
  timerId = setTimeout(() => {
    tapTimes.value = 0
  }, 1000)
}

const isVip = computed(() => {
  return userStore.$state.vipEndTime && isAfter(parseISO(userStore.$state.vipEndTime), Date.now())
})

onMounted(() => {
  userStore.init({ force: true })
})

function gotoVip() {
  router.replace({ name: 'vip' })
}
</script>

<template>
  <div>
    <div class="cover px-30px py-27px flex items-center justify-between">
      <div class="flex space-x-14px">
        <img class="w-15 h-15 rounded-full" src="@/assets/default-avatar.png" alt="avatar">
        <div>
          <p class="font-bold">
            {{ userStore.$state.memberName }}
          </p>
          <p class="text-11px text-#666">
            账号ID {{ userStore.$state.code }}
          </p>
          <p class="text-11px text-#666">
            我的金币 {{ userStore.$state.coinBalance }}
          </p>
        </div>
      </div>
      <BizTrackClick name="立即充值" type="link">
        <router-link
          :to="{ name: 'pay' }" replace
          class="
            px-14px py-7px text-white text-13px font-bold rounded-full
            bg-gradient-to-r from-#FF6E0F to-#FF5970
          "
        >
          立即充值
        </router-link>
      </BizTrackClick>
    </div>
    <!-- VIP -->
    <div v-if="configStore.$state.renewVipState || userStore.$state.vipEndTime" class="px-20px py-10px pt-0 bg-white">
      <div class="vip px-5 flex items-center justify-between text-#66421F">
        <div class="flex items-start space-x-2">
          <img class="mt-1 w-19px" src="@/assets/icon-vip.png" alt="图标:VIP">
          <div>
            <p class="text-14px font-bold">
              {{ isVip ? '您已开通会员' : '开通VIP，万本小说随心看' }}
            </p>
            <p v-if="isVip" class="text-12px">
              将于{{ format(parseISO(userStore.$state.vipEndTime), 'yyyy/MM/dd') }}到期
            </p>
            <p v-else class="text-12px">
              最低仅需0.58元/天
            </p>
          </div>
        </div>
        <div v-if="configStore.$state.renewVipState" class="px-3 py-1.5 bg-#794902 text-#F7DFBB text-13px rounded-full" @click="gotoVip">
          {{ isVip ? '立即续费' : '立即开通' }}
        </div>
      </div>
    </div>
    <div class="px-20px bg-white">
      <BizTrackClick name="福利中心" type="link">
        <router-link
          :to="{ name: 'welfare' }" replace
          class="w-full h-61px flex items-center justify-between"
        >
          <div class="flex items-center space-x-7px">
            <img class="w-6 h-6" src="@/assets/icon-welfare-center.png" alt="图标:阅读记录">
            <p class="text-15px font-bold">
              福利中心
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
      <BizTrackClick name="阅读记录" type="link">
        <router-link
          :to="{ name: 'history', query: { from: 'member' } }" replace
          class="w-full h-61px flex items-center justify-between border-t border-##E6E6E6"
        >
          <div class="flex items-center space-x-7px">
            <img class="w-6 h-6" src="@/assets/icon-history-record.png" alt="图标:阅读记录">
            <p class="text-15px font-bold">
              阅读记录
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
      <BizTrackClick name="充值记录" type="link">
        <router-link
          :to="{ name: 'recharge' }" replace
          class="w-full h-61px flex items-center justify-between border-t border-##E6E6E6"
        >
          <div class="flex items-center space-x-7px">
            <img class="w-6 h-6" src="@/assets/icon-recharge-record.png" alt="图标:充值记录">
            <p class="text-15px font-bold">
              充值记录
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
      <BizTrackClick name="阅读记录" type="link">
        <router-link
          :to="{ name: 'consume' }" replace
          class="w-full h-61px flex items-center justify-between border-t border-##E6E6E6"
        >
          <div class="flex items-center space-x-7px">
            <img class="w-6 h-6" src="@/assets/icon-consume-record.png" alt="图标:消费记录">
            <p class="text-15px font-bold">
              消费记录
            </p>
          </div>
          <i-icon-park-outline-right class="text-#999" />
        </router-link>
      </BizTrackClick>
    </div>

    <div class="mt-20 flex justify-center text-xs text-#999">
      <p @click="onDebugEnter">
        版本号: {{ appVersion }}
      </p>
    </div>

    <BasicTabbar tab="member" />
  </div>
</template>

<style scoped>
.cover {
  width: 100%;
  height: 113px;
  background: url('@/assets/member-cover-background.png');
  background-size: 100% 100%;
}
.vip {
  width: 100%;
  height: 71px;
  background: url('@/assets/member-vip-background.png');
  background-size: 100% 100%;
}
</style>
