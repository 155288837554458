<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'

const props = withDefaults(defineProps<{
  start?: number
  end: number
  duration?: number
  decimal?: number
}>(), {
  start: 0,
  duration: 1000,
  decimal: 0,
})

const currentCount = ref(props.start)

watch(() => props.end, (newValue, oldValue) => {
  animateCount(oldValue, newValue)
})

onMounted(() => {
  animateCount(props.start, props.end)
})

function animateCount(start: number, end: number) {
  const delta = end - start
  const startTime = performance.now() || Date.now()

  const step = (currentTime: number) => {
    let progress = (currentTime - startTime) / props.duration
    progress = Math.min(progress, 1)

    currentCount.value = +(start + delta * progress).toFixed(props.decimal)

    if (progress < 1)
      requestAnimationFrame(step)
    else
      currentCount.value = end
  }

  requestAnimationFrame(step)
}
</script>

<template>
  <div>
    {{ currentCount }}
  </div>
</template>
