<script lang="ts" setup>
import { computed, ref } from 'vue'
import queryString from 'query-string'
import { useRoute, useRouter } from 'vue-router/composables'
import { useClipboard } from '@vueuse/core'
import { useConfigStore, useQueryStore, useToastStore, useUserStore } from '@/stores'
import { CODE_SOURCE, STORAGE_TOKEN, isIOS } from '@/constants'
import { generateThirdOAuthUrl } from '@/wechat'
import { $setAppId, $setToken } from '@/helpers'

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const queryStore = useQueryStore()
const { $toast } = useToastStore()
const { copy } = useClipboard({ legacy: true })

const code = ref<string>(route.query.code as string ?? '')
function onCopyAuthCode() {
  copy(code.value)
  $toast('复制成功')
}

async function onDebugOAuthLogin() {
  if (!queryStore.$state.max_app_id) {
    // eslint-disable-next-line no-alert
    window.alert('URL 上必须包含 `max_app_id`')
    return
  }
  const configStore = useConfigStore()
  await configStore.init()
  const oAuthDomain = configStore.$state.oauthDomain
  const oAuthURL = generateThirdOAuthUrl({
    appId: configStore.$state.mpAppId,
    componentId: configStore.$state.componentAppId,
    redirect: `${oAuthDomain}/authorize?redirect=${window.encodeURIComponent(`${window.location.origin}/authorize?${
      queryString.stringify({
        debug: 1,
        next: window.encodeURIComponent(route.fullPath),
        codeSource: CODE_SOURCE.TARGET,
        appId: configStore.$state.appId,
        max_app_id: queryStore.$state.max_app_id,
        max_pid: queryStore.$state.max_pid,
        max_sid: queryStore.$state.max_sid,
      })
    }`)}`,
  })
  window.location.replace(oAuthURL)
}

const maxAppId = ref(queryStore.$state.max_app_id ?? '')
const token = ref(localStorage.getItem(STORAGE_TOKEN) ?? '')
function onMockLogin() {
  if (!maxAppId.value) {
    // eslint-disable-next-line no-alert
    window.alert('URL 上必须包含 `max_app_id`')
    return
  }
  if (!maxAppId.value || !token.value) {
    // eslint-disable-next-line no-alert
    window.alert('请输入 `token`')
    return
  }

  $setAppId(maxAppId.value)
  $setToken(token.value)
  router.replace({ name: 'home' })
}

function onEnableTrackDebug() {
  router.replace({ name: 'home', query: { open_devtool_web: 'true' } })
}

const appInfo = computed(() => {
  return {
    ...queryStore.$state,
    max_os: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows
    max_version: __APP_VERSION__,
    memberId: userStore.$state.memberId,
    memberCode: userStore.$state.code,
  }
})
function onCopyAppInfo() {
  copy(JSON.stringify(appInfo.value, null, 2))
  $toast('复制成功')
}
</script>

<template>
  <div class="min-h-screen bg-white">
    <BasicNavbar />

    <div class="px-5 pt-20">
      <div class="mt-4">
        <p class="font-bold">
          # DEBUG: OAuth 授权
        </p>
        <template v-if="code">
          <textarea
            v-model="code" disabled class="mt-4 w-full h-20 px-4 py-2 bg-#f8f8f8 rounded"
          />
          <div class="flex justify-end">
            <button
              class="mt-4 px-4 py-2 text-white bg-black rounded"
              @click="onCopyAuthCode"
            >
              复制 OAuth Code
            </button>
          </div>
        </template>
        <div>
          <button
            class="mt-4 px-4 py-2 text-white bg-black rounded"
            @click="onDebugOAuthLogin"
          >
            DEBUG: OAuth 登录
          </button>
          <p class="text-sm text-gray">
            获取 OAuth Code 方便调试
          </p>
        </div>
      </div>

      <div class="mt-4">
        <p class="font-bold">
          # 模拟登录
        </p>
        <div class="mt-4 flex items-center space-x-4">
          <p>max_app_id: </p>
          <input v-model="maxAppId" class="px-4 py-2 flex-1 bg-#f8f8f8 rounded" disabled>
        </div>
        <div class="mt-4 flex items-center space-x-4">
          <p>token: </p>
          <textarea
            v-model="token" class="mt-4 flex-1 h-20 px-4 py-2 bg-#f8f8f8 rounded"
            placeholder="复制 `token` 到此文本框"
          />
        </div>
        <button
          class="mt-4 px-4 py-2 text-white bg-black rounded"
          @click="onMockLogin"
        >
          登录
        </button>
      </div>

      <div class="mt-4">
        <p class="font-bold">
          # 开启火山云埋点 DEBUG
        </p>
        <button
          class="mt-4 px-4 py-2 text-white bg-black rounded"
          @click="onEnableTrackDebug"
        >
          开启
        </button>
      </div>

      <div class="mt-4">
        <p class="font-bold">
          # 应用信息
        </p>
        <pre class="mt-4 w-full overflow-scroll">{{ JSON.stringify(appInfo, null, 2) }}</pre>
        <div class="flex justify-end">
          <button
            class="mt-4 px-4 py-2 text-white bg-black rounded"
            @click="onCopyAppInfo"
          >
            复制
          </button>
        </div>
      </div>
    </div>

    <!-- TODO: VConsole -->
  </div>
</template>
