<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useEventBus, useStorage } from '@vueuse/core'
import { isPast } from 'date-fns'
import { api } from '@/api'
import { useUserStore } from '@/stores'
import { PAY_LOCATION_PAGE, STORAGE_RECHARGE_INTERCEPTION } from '@/constants'
import router from '@/router'
import { diffSeconds, fen2yuan } from '@/utils'
import { trackRealtime } from '@/track'
import { $getToken } from '@/helpers'

const user = useUserStore()
const route = useRoute()
const products = ref<GetRechargetemplateGetproductlistResponse['data']>([])

// 充值拦截
const rechargeInterception = useStorage<Record<string, any>>(STORAGE_RECHARGE_INTERCEPTION, { _exist: false })
const eventBusRechargeInterception = useEventBus<string>('modal:rechargeInterception')

const canceled = ref(false)
const skipRechargeInterception = ref(false)

onMounted(() => {
  if (route.query.skipRechargeInterception)
    skipRechargeInterception.value = true

  // Note: 如果是支付取消返回的页面
  if (route.query.cancel) {
    canceled.value = true

    if (!skipRechargeInterception.value && canceled.value)
      eventBusRechargeInterception.emit('open')

    skipRechargeInterception.value = true

    router.replace({
      query: {
        ...route.query,
        cancel: undefined,
        skipRechargeInterception: undefined,
      },
    })
  }

  window.collectEvent('elementShow', {
    type: '充值页',
    page: '充值页',
  })
  // Note: remove when expired
  if (isPast(rechargeInterception.value?.expiredAt))
    rechargeInterception.value = { _exist: false }
})
onUnmounted(() => {
  if (!skipRechargeInterception.value)
    eventBusRechargeInterception.emit('open')
})

// 充值加购
const rechargeAddState = ref(false)
const rechargeAdd = ref<any>()
async function getRechargeAdd() {
  try {
    const { data } = await api.get('/recharge/strategy/getStrategy')
    if (data?.id) {
      data.productMap = data.productMapList ?? {}
      if (Object.keys(data.productMap).length)
        rechargeAdd.value = data
      if (data.rechargeStrategyFlag === 1)
        rechargeAddState.value = true

      // 15: 充值加购
      window.collectEvent('elementShow', {
        item_type: 15, // 15: 充值加购
        item_id: data.id,
      })
      trackRealtime('pageView',
        { activityId: data.id, userToken: $getToken() },
        { sourceId: data.id, sourceType: 15 }, // 15: 充值加购
      )
    }
  }
  catch {
    console.error('获取充值加购失败')
  }
}
function onChangeShowAdd() {
  rechargeAddState.value = !rechargeAddState.value

  // 15: 充值加购
  window.collectEvent('buttonClick', {
    page: '充值页',
    button_name: rechargeAddState.value ? '打开加购' : '关闭加购',
    state: rechargeAddState.value,
  })
  window.collectEvent('elementClick',
    { item_type: 15, item_id: rechargeAdd.value.id, state: rechargeAddState.value },
  )
  trackRealtime('activityReceived',
    { activityId: rechargeAdd.value?.id ?? '', userToken: $getToken(), state: rechargeAddState.value },
    { sourceId: rechargeAdd.value?.id, sourceType: 15 },
  )
}

onMounted(async () => {
  const { data } = await api.get<any, GetRechargetemplateGetproductlistResponse>('/rechargeTemplate/getProductList')
  products.value = data.sort((a, b) => a.orderNumber - b.orderNumber)
  await getRechargeAdd()
})
</script>

<template>
  <div class="min-h-screen bg-white">
    <BasicNavbar />

    <!-- Cover -->
    <div class="pt-44px px-5">
      <div>
        <p class="text-13px text-#333">
          账户余额
        </p>
        <p class="flex items-baseline space-x-2">
          <span class="text-32px text-#333 font-bold">{{ user.$state.coinBalance }}</span>
          <span class="relative bottom-1 text-13px text-#666">金币</span>
        </p>
      </div>

      <!-- 充值拦截活动 -->
      <div v-if="rechargeInterception?._exist" class="recharge-interception flex items-center justify-between text-white">
        <div class="flex items-center rounded relative">
          <p class="px-1 text-13px text-white font-bold">
            {{ fen2yuan(rechargeInterception.redPacketAmount) }}元劵使用中
          </p>
        </div>
        <div class="flex items-baseline">
          <BasicCountdown
            v-if="rechargeInterception.countDown"
            :seconds="diffSeconds(Date.now(), rechargeInterception.expiredAt)"
            @end="rechargeInterception = { _exist: false }"
          />
          <span class="ml-2 text-11px text-#00000099">后失效</span>
        </div>
      </div>

      <!-- 充值加购 -->
      <div v-else-if="rechargeAdd" class="add-wrapper">
        <div class="add-text">
          账户余额 {{ user.$state.coinBalance }}
          <span class="icon">金币</span>
        </div>
        <div class="add-btn">
          <span class="add-btn-text">{{ rechargeAdd.name }}</span>
          <BasicSwitch :is-open="rechargeAddState" @change="onChangeShowAdd" />
        </div>
      </div>

      <!-- 付费选项 -->
      <div class="mt-4 grid grid-cols-2 gap-10px relative">
        <BizTrackClick v-for="item in products" :key="item.productId" :name="item.name" type="button">
          <BizPayProduct
            :product="item" :pay-location="PAY_LOCATION_PAGE.MEMBER"
            :recharge-interception="rechargeInterception" :show-recharge-add="rechargeAddState" :recharge-add="rechargeAdd"
            :skip-recharge-interception="skipRechargeInterception"
            @click="onChangeShowAdd"
          />
        </BizTrackClick>
      </div>
      <div class="mt-7.5">
        <p class="text-13px text-#333 font-bold">
          充值说明
        </p>
        <ol class="mt-10px text-13px text-#666 list-decimal ml-4 leading-relaxed">
          <li>赠送金币有效期30天，支付解锁章节时优先扣除赠送金币</li>
          <li>充值后5分钟内到账，如未到账可联系客服</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style scoped>
.add-wrapper {
  padding: 15px 5px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.add-text {
  color: #666;
}
.add-btn {
  color: #dc9300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-btn-text {
  margin-right: 5px;
}

.recharge-interception {
  background: url('@/assets/bg-recharge-interception.png');
  background-size: 100% 100%;
  height: 30px;
  padding-left: 25px;
}

:deep(.countdown .bg-primary) {
  background: #FF6E0F66;
}
</style>
