<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { ERROR_PAGE_LEVEL } from '@/constants'
import { windowClose } from '@/wechat'

const props = withDefaults(
  defineProps<{
    level: ERROR_PAGE_LEVEL
  }>()
  , {
    level: ERROR_PAGE_LEVEL.DEFAULT,
  },
)

const route = useRoute()
const message = ref('')
onMounted(() => {
  try {
    message.value = window.decodeURIComponent(route.query.message as string) || '服务器错误'
    window.collectEvent('onError', {
      error_title: message.value,
      err_msg: message.value,
      error_type: 'page',
      err_code: props.level,
    })
  }
  catch {}
})

const router = useRouter()
function onRetry() {
  router.replace({ path: window.decodeURIComponent(route.query.redirect as string ?? '/') })
}
function onClose() {
  windowClose()
}
</script>

<template>
  <div>
    <BasicNavbar v-if="level === ERROR_PAGE_LEVEL.DEFAULT" />
    <div class="py-4 px-8 flex flex-col items-center justify-center h-screen bg-white">
      <img class="w-100" src="@/assets/cover-error.png">

      <template v-if="level === ERROR_PAGE_LEVEL.DEFAULT">
        <p class="mt-8 w-full text-#666 text-center">
          {{ message }}
        </p>

        <button
          class="mt-15 px-4 py-2 text-primary border-primary border-1 border-solid rounded-full"
          @click="onRetry"
        >
          重试
        </button>
      </template>
      <template v-else-if="level === ERROR_PAGE_LEVEL.FATAL">
        <p class="mt-4 w-full text-#666 text-center">
          {{ message }}
        </p>
        <p class="mt-4 w-full text-#666 text-center">
          请关闭当前页面，<span class="text-#EF4142">重新点击下方公众号菜单</span>
        </p>

        <button
          class="mt-15 px-4 py-2 text-primary border-primary border-1 border-solid rounded-full"
          @click="onClose"
        >
          关闭当前页面
        </button>
      </template>
    </div>
  </div>
</template>
