<script lang="ts" setup>
import { onMounted } from 'vue'
import Swiper from 'swiper'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

defineProps<{
  urls: string[]
}>()

defineEmits<{
  (event: 'click', index: number): void
}>()

onMounted(() => {
  // eslint-disable-next-line no-new
  new Swiper('.banner.swiper', {
    modules: [Pagination, Autoplay],
    spaceBetween: 0,
    autoplay: {
      delay: 2000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
  })
})
</script>

<template>
  <div class="banners">
    <div class="banner" flex="~ row">
      <div class="banner swiper" flex="~ row">
        <div class="swiper-wrapper" flex="~ row">
          <div v-for="(url, index) in urls" :key="index" class="swiper-slide">
            <BizTrackClick name="轮播图" type="image" :extra="{ index }">
              <img :src="url" class="rounded overflow-hidden" @click="$emit('click', index)">
            </BizTrackClick>
          </div>
        </div>
        <div class="swiper-pagination flex items-center justify-center" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.banners {
  border-radius: 5px;
  overflow: hidden;
}
.banner {
  width: 100%;
  height: 130px;
}
.swiper-slide {
  border-radius: 5px;
  overflow: hidden;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

:deep(.swiper-pagination-bullet) {
  width: 15px;
  height: 4px;
  opacity: 0.74;
  background: white;
  opacity: 0.4;
  border-radius: 20px;
}

:deep(.swiper-pagination-bullet-active) {
  display: block;
  background: #FD742DCC;
  opacity: 0.8;
}
</style>
