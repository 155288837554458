<script setup lang="ts">
import { onMounted, ref } from 'vue'
import queryString from 'query-string'
import { api } from '@/api'

const modal = ref(false)
const coin = ref()

onMounted(async () => {
  const parsed = queryString.parseUrl(window.location.href)
  const { consumeCode, coinNum, consumeType } = parsed.query
  coin.value = coinNum
  if (consumeCode && coinNum && consumeType === 'sop') {
    const { data } = await api.post('/wp/fans/consume/code', {
      consumeCode,
      type: 'sop',
    })
    if (data)
      modal.value = true
  }
})
</script>

<template>
  <BasicModal v-model="modal" :closable="false" :transparent="true">
    <div class="dialog">
      <div class="content">
        <div class="title">
          天降福利
        </div>
        <div class="text">
          恭喜您获得<span class="coin-num">{{ coin }}金币</span>
        </div>
        <div class="btn" @click="modal = false">
          立即领取
        </div>
      </div>
    </div>
    <div class="coin" />
  </BasicModal>
</template>

<style scoped>
.dialog {
  width: 272px;
  height: 220px;
  border-radius: 15px 50px 15px 15px;
  background: #FFF;
  overflow: hidden;
  position: relative;
}
.coin {
  width: 85px;
  height: 85px;
  background-image: url('@/assets/icon-coin-multi.png');
  background-size: 85px auto;
  top: -30px;
  position: absolute;
  right: 20px;
}
.content {
  margin-top: 10px;
  width: 100%;
  padding: 30px;
}
.content .title {
  color: #FF6E0F;
  font-size: 17px;
}
.content .text {
  margin-top: 10px;
  color: #1A1A1A;
  font-size: 20px;
  font-weight: bold;
}
.content .text .coin-num {
  margin-left: 0.5em;
  color: #FF6E0F;
}
.content .btn {
  width: 100%;
  padding: 12px 0;
  text-align: center;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  margin-top: 32px;
  border-radius: 25px;
  border: 2px solid #FFC500;
  background: linear-gradient(90deg, #FF6E0F 0%, #FF5970 100%);
  box-shadow: 0px 4px 10px 0px rgba(255, 255, 255, 0.50) inset, 2px 8px 10px 0px rgba(255, 193, 72, 0.70) inset, -4px -10px 10px 0px rgba(247, 55, 82, 0.60) inset;
}
</style>
