<!--
- 自动授权页面, 接受三方(如: App) 跳转到此页面
- 通过 `code` 获取 `token` 后, 重定向到 `redirect` 指定的页面上
-->

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import queryString from 'query-string'
import { api } from '@/api'
import { generateThirdOAuthUrl } from '@/wechat'
import { CODE_SOURCE, ERROR_PAGE_LEVEL } from '@/constants'
import { useQueryStore, useUserStore } from '@/stores'
import { normalizeHttps } from '@/utils'

const props = defineProps<{
  redirect?: string
  next?: string
  code?: string
  state?: string
}>()

const userStore = useUserStore()
const queryStore = useQueryStore()
const router = useRouter()
const route = useRoute()
const failed = ref('')

onMounted(async () => {
  if (props.redirect) {
    window.location.replace(`${window.decodeURIComponent(props.redirect)}&code=${props.code}`)
    return
  }

  if (!props.code) {
    failed.value = '缺少 code 参数'
    return
  }

  // DEBUG mode, 用户获取 code 方便调试
  if (route.query.debug) {
    router.replace({ path: window.decodeURIComponent(props.next ?? '/'), query: { code: props.code } })
    return
  }

  // Note: 如果有登录状态, 直接跳转
  if (userStore.isLogin()) {
    router.replace({ path: window.decodeURIComponent(props.next ?? '/') })
    return
  }

  try {
    // 1. First login
    const { data } = await api.post<any, PostMemberWxauthLoginResponse>('/member/wxAuth/login', {
      appId: route.query.appId,
      code: props.code,
      codeSource: route.query.codeSource,
      wpOpenId: route.query.wpOpenId,
    })

    // 2. If don't given `token`, redirect oauth again
    if (!data.token) {
      const oAuthDomain = normalizeHttps(data.needAuthUrlDomain)
      const oAuthURL = generateThirdOAuthUrl({
        appId: data.needAuthMpAppId,
        componentId: data.needAuthComponentAppId,
        redirect: `${oAuthDomain}/authorize?redirect=${window.encodeURIComponent(`${window.location.origin}/authorize?${
          queryString.stringify({
            next: window.encodeURIComponent(props.next ?? '/'),
            codeSource: CODE_SOURCE.FALLBACK,
            appId: route.query.appId,
            wpOpenId: data.openId,
            max_app_id: queryStore.$state.max_app_id,
            max_pid: queryStore.$state.max_pid,
            max_sid: queryStore.$state.max_sid,
          })
        }`)}`,
      })
      window.location.replace(oAuthURL)
      return
    }

    // Login with token
    await userStore.login(data.token, data.isNewUser)
  }
  catch (error: any) {
    console.error(error)
    failed.value = error.msg || '服务器错误'
    router.replace({
      name: 'error',
      query: {
        level: ERROR_PAGE_LEVEL.FATAL,
        message: '很抱歉，授权失败',
        redirect: window.encodeURIComponent(window.location.href),
      },
    })
    return
  }

  router.replace({ path: window.decodeURIComponent(props.next ?? '/') })
})
</script>

<template>
  <div class="w-full h-screen bg-white">
    <div class="pt-20vh flex justify-center">
      <BasicSpinner v-if="!failed" class="mx-auto" />
    </div>
    <p class="mt-8 font-bold text-center">
      <span>{{ failed || '授权中' }}</span>
    </p>
    <div class="mt-40 flex justify-center" />
  </div>
</template>
