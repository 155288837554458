<script setup lang="ts">
import { PAY_LOCATION_PAGE, STORAGE_SIGN_PRODUCT_ID } from '@/constants'
import { fen2yuan } from '@/utils'
import { useConfigStore, useQueryStore, useTrackValueStore, useUserStore } from '@/stores'
import { $getAppId, $gotoPayProduct } from '@/helpers'
import { api } from '@/api'

type Product = GetRechargetemplateGetproductlistResponse['data'][0]

const props = withDefaults(
  defineProps<{
    product: Product
    highlight?: boolean
    payLocation?: PAY_LOCATION_PAGE
    contentId?: string
    contentName?: string
    chapterId?: string
    chapterIndex?: number
    skipSign?: boolean
  }>(),
  {
    highlight: false,
    payLocation: PAY_LOCATION_PAGE.NONE,
    skipSign: false, // 不需要签约, 直接去支付商品
  },
)

const userStore = useUserStore()
const configStore = useConfigStore()
const queryStore = useQueryStore()
const trackValueStore = useTrackValueStore()

async function onSign() {
  window.collectEvent('pagepurClick', {
    page: 'VIP',
    button: '破冰商品',
    money: fen2yuan(props.product.price),
  })

  const productId = props.product.id || props.product.productId
  // Note: 如果是章节付费弹窗添加 `skipPosition`
  let redirect = window.location.href
  if (props.payLocation === PAY_LOCATION_PAGE.CHAPTER && !window.location.href.includes('skipPosition'))
    redirect = `${window.location.href}&skipPosition=1`

  // 如果不需要签约, 直接去支付商品
  if (props.skipSign) {
    $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: props.payLocation, redirect })
    return
  }

  // Note: check exist sign subscribe?
  const { data: exist } = await api.post('/pay/getContractInfo', {
    productId,
  })

  // 如果存在合约, 直接去支付商品
  if (exist) {
    $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: props.payLocation, redirect })
    return
  }
  const payload = {
    appId: $getAppId(),
    memberId: userStore.$state.memberId,
    productId,
  }
  window.localStorage.setItem(STORAGE_SIGN_PRODUCT_ID, productId)
  const { data } = await api.post<any, PostPayCreatewechatmpcontractResponse>('/pay/createWechatMPContract', payload)
  const subscribeUrl = data?.returnUrl as string
  window.location.href = subscribeUrl
}
</script>

<template>
  <div
    class="card w-full h-100px px-30px rounded-3 relative border-1px border-#0000001A"
    @click="onSign"
  >
    <div class="h-full flex justify-between items-center text-#794902">
      <div>
        <p class="text-20px font-bold">
          {{ product.name }}
        </p>
        <p class="text-#9A9A9A text-12px line-through">
          {{ product.description }}
        </p>
      </div>
      <p class="font-bold flex items-baseline justify-center">
        <span class="text-13px">￥</span>
        <span class="text-39px">{{ fen2yuan(product.price) }}</span>
      </p>
    </div>

    <!-- left corner -->
    <p
      v-if="product.cornerTip"
      class="absolute -top-2 px-2 py-2px text-white text-11px rounded-tl-2 rounded-br-2 z-1"
      :class="highlight
        ? '-left-2px bg-gradient-to-r from-#EB5C4C to-#FE5D5C'
        : '-left-1px bg-gradient-to-r from-#D38B2B to-#F1CA97'"
    >
      {{ product.cornerTip }}
    </p>
  </div>
</template>

<style scoped>
.card {
  background: url('@/assets/single-ice-break-background.png');
  background-size: 100% 100%;
}
</style>
