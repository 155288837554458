import axios from 'axios'
import UAParser from 'ua-parser-js'
import { $uuid } from './helpers'
import { useQueryStore } from './stores'
import { isIOS } from '@/constants'

const parser = new UAParser()

/**
 * Mode
 * `debug`: only output to console.warn
 * `realtime`: send track event every time
 * `standard`: cache events and send w/ polling 2.5s
 */
let mode: 'debug' | 'realtime' | 'standard' = 'standard'
if (import.meta.env.MODE === 'development')
  mode = 'debug'
else if (import.meta.env.MODE === 'testing')
  mode = 'realtime'

const POLLING_TIME = 2500 // 2.5s

// TODO: Sync to localStorage for fallback
// refs: https://yapi.17k.com/project/531/interface/api/18669
const payload: Record<string, any> = {
  app: 'h5-novel',
  cm: {
    ua: window.navigator.userAgent,
    uuid: $uuid(),
    t: '', // 上报时间
    uid: '', // 用户 ID
    av: __APP_VERSION__,
    md: parser.getDevice().model, // 手机型号
    ba: parser.getDevice().vendor, // 手机品牌
    hw: `${window.innerHeight}X${window.innerWidth}`, // heightXwidth,屏幕宽高
    ct: 1, //  contentType, 1: 小说
    ssa: 1, // appType, 1: 微信公众号
    aid: '', // max_app_id
    pid: '', // max_pid
    sid: '', // max_sid
  },
  et: [],
}

const api = axios.create({
  baseURL: import.meta.env.VITE_TRACK_DOMAIN,
  responseType: 'json',
})

api.interceptors.request.use((options) => {
  const queryStore = useQueryStore()
  options.params = {
    max_sid: queryStore.$state.max_sid,
    max_pid: queryStore.$state.max_pid,
    max_app_id: queryStore.$state.max_app_id,
    max_platform: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows
    max_os: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows
    max_version: __APP_VERSION__,
  }
  return options
})

async function tracking() {
  const queryStore = useQueryStore()

  payload.cm.aid = queryStore.$state.max_app_id
  payload.cm.sid = queryStore.$state.max_sid
  payload.cm.pid = queryStore.$state.max_pid
  payload.cm.t = Date.now()

  // 如果没有 uid 或者有 aid 不上报, Case: 用户授权失败
  if (!payload.cm.uid || !payload.cm.aid)
    return

  return api.post('/tracking/report', payload)
}

let interval: any
let reporting = false
export function initTrack(options?: { mode: typeof mode }) {
  if (options)
    mode = options.mode

  if (mode === 'debug')
    return

  if (interval)
    return

  polling()
  interval = setInterval(polling, POLLING_TIME)
  function polling() {
    if (reporting)
      return
    if (payload.et.length === 0)
      return

    reporting = true

    tracking().then(() => {
      payload.et = []
    }).finally(() => {
      reporting = false
    })
  }
}

let isFirstTrack = true
export function track(name: string, params: Record<string, any>) {
  if (mode === 'debug') {
    console.warn(`[track] ${name}: `, { payload, params })
    return
  }

  if (mode === 'realtime') {
    payload.et.push({
      ett: Date.now(),
      en: name,
      kv: params,
    })
    tracking()
    payload.et = [] // Note: don't await tracking, Avoiding concurrency
    return
  }

  payload.et.push({
    ett: Date.now(),
    en: name,
    kv: params,
  })

  if (isFirstTrack) {
    isFirstTrack = false
    tracking()
  }
}

export function trackRealtime(name: string, params: Record<string, any>, payloadCM: Record<string, any> = {}) {
  const queryStore = useQueryStore()

  payload.cm.aid = queryStore.$state.max_app_id
  payload.cm.sid = queryStore.$state.max_sid
  payload.cm.pid = queryStore.$state.max_pid
  payload.cm.t = Date.now()

  // 如果没有 uid 或者有 aid 不上报, Case: 用户授权失败
  if (!payload.cm.uid || !payload.cm.aid) {
    console.warn('[track] uid or aid is empty: ', payload)
    return
  }

  return api.post('/tracking/report/realtime', {
    ...payload,
    cm: {
      ...payload.cm,
      ...payloadCM,
    },
    et: [{
      ett: Date.now(),
      en: name,
      kv: params,
    }],
  })
}

/**
 * 更新 track payload
 *
 * @example
 * updateTrackPayload('cm.uid', '123')
 */
export function updateTrackPayload(key: string, value: any) {
  const keys = key.split('.')
  let obj = payload
  for (let i = 0; i < keys.length - 1; i++)
    obj = obj?.[keys[i]] ?? {}

  obj[keys[keys.length - 1]] = value
}
