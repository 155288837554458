<script>
export default {
  props: ['isOpen'],
  emits: ['change'],
  data() {
    return {
      // isOpen: false
    }
  },
  methods: {
    toggleSwitch() {
      // 子组件不能直接修改父组件的数据，要通过$emit
      this.$emit('change')
    },
  },

}
</script>

<template>
  <div>
    <div class="switch" :style="{ backgroundColor: isOpen ? '#ff9928' : '#ccc' }" @click="toggleSwitch">
      <span class="switch-circle" :class="{ on: isOpen }" />
    </div>
  </div>
</template>

<style scoped>
.switch {
  position: relative;
  border-radius: 20px;
  border: 1px solid #d1d1d1;
  width: 40px;
  height: 23px;
  background: rgba(229, 230, 235, 1);
}
.switch-circle {
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #d1d1d1;
  box-shadow: 0 1px 1px #ccc;
}

.on {
  right: 0;
  border-color: #ff9928;
  transform: translate(X);
  transition: transform 0.5s, right 0.5s;
}
</style>
