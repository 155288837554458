<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router/composables'
import { track } from '@/track'

const props = withDefaults(
  defineProps<{
    id?: string
    name: string
    type: 'page' | 'modal' | 'popup' | 'image' | 'button'
    extra?: Record<string, any>
  }>(),
  {
    id: 'elementShow',
  },
)

const route = useRoute()
onMounted(() => {
  track(props.id, {
    element_name: props.name,
    element_type: props.type,
    current_page: route.fullPath,
    current_page_title: route.meta?.title ?? '',
    ...props.extra,
  })
})
</script>

<template>
  <div>
    <slot />
  </div>
</template>
