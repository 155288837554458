<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router/composables'

const route = useRoute()
const url = ref<string>(route.query.url as string)
</script>

<template>
  <div class="page">
    <BasicNavbar />

    <div class="context">
      <h2 class="title">
        关注企业微信继续阅读
      </h2>
      <p>由于版权问题，请关注后继续阅读，每日免费领书币</p>
      <p>每日免费领书币，最高月送<span class="red">2000</span>书币!</p>
      <div class="qrcode">
        <img
          :src="url"
          alt="关注企业微信继续阅读"
        >
      </div>
      <h3 class="title">
        长按上图识别二维码关注
      </h3>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$red: #7E1124;

.page {

  .red {
    color: $red;
  }

  .context {
    font-size: 14px;
    text-align: center;
    padding: 3em;

    h2,h3 {
      font-weight: bold;
      color: $red;
    }

    h2 {
      margin: 3em 0 2em;
      font-size: 1.7em;
    }

    h3 {
      font-size: 1.5em;
      margin: 0.5em 0;
    }

    p {
      margin: 2em 0 0;
    }

    .qrcode {
      width: 1.5em * 11;
      margin: 3em auto 0;

      img {
        width: 100%;
      }
    }
  }
}
</style>
