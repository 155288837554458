<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { format, isAfter, parseISO } from 'date-fns'
import { useRoute } from 'vue-router/composables'
import { useConfigStore, useLoadingStore, useQueryStore, useToastStore, useTrackValueStore, useUserStore } from '@/stores'
import { api } from '@/api'
import { PAY_LOCATION_PAGE, STORAGE_SIGN_PRODUCT_ID } from '@/constants'
import { $formatErrorMessage, $gotoPayProduct } from '@/helpers'
import { sleep } from '@/utils'
import router from '@/router'

const route = useRoute()
const userStore = useUserStore()
const configStore = useConfigStore()
const queryStore = useQueryStore()
const trackValueStore = useTrackValueStore()
const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()

const isVip = computed(() => {
  return userStore.$state.vipEndTime && isAfter(parseISO(userStore.$state.vipEndTime), Date.now())
})
const formatVipExpire = computed(() => {
  try {
    return format(parseISO(userStore.$state.vipEndTime), 'yyyy/MM/dd')
  }
  catch { return '' }
})

const productFetching = ref(false)
const products = ref<any>([])
onMounted(async () => {
  try {
    productFetching.value = true
    await userStore.init({ force: true })
    const { data } = await api.get('/rechargeTemplate/getMyVipProductList')
    products.value = data
  }
  catch (error) {
    $toast($formatErrorMessage(error))
  }
  finally {
    productFetching.value = false
  }
})

const recommendBook = ref<any>()
onMounted(async () => {
  try {
    const { data: recentlyBook } = await api.get<any, GetReadLastPlayResponse>('/read/last/play')
    if (recentlyBook) {
      recommendBook.value = recentlyBook
      return
    }
    const { data: recommend } = await api.get<any>('/read/next/content?contentId=0')
    if (recommend)
      recommendBook.value = recommend
  }
  catch {} // ignore error
})

onMounted(async () => {
  // Note: 签约页面返回会携带 `from_wxpay=1`
  // refs: https://pay.weixin.qq.com/wiki/doc/api/wxpay_v2/papay/chapter3_1.shtml
  const productId = window.localStorage.getItem(STORAGE_SIGN_PRODUCT_ID)
  if (route.query.from_wxpay && productId) {
    try {
      $showLoading()
      window.localStorage.removeItem(STORAGE_SIGN_PRODUCT_ID)
      const redirect = window.location.href

      // retry three times
      let times = 3
      while (times--) {
        const { data: exist } = await api.post('/pay/getContractInfo', {
          productId,
        })
        if (exist) {
          $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: PAY_LOCATION_PAGE.VIP, redirect })
          return
        }
        await sleep(1000)
      }

      return $toast('签约失败')
    }
    finally {
      $hideLoading()
    }
  }
})

function gotoRecommend() {
  router.replace({ name: 'chapter', params: { contentId: recommendBook.value.contentId, chapterId: '0' } })
}
function gotoHome() {
  router.replace({ name: 'home' })
  window.collectEvent('pagepurClick', {
    page: 'VIP',
    button: '更多精选',
  })
}
</script>

<template>
  <div>
    <BasicNavbar class="text-white font-bold">
      <p>开通 VIP</p>
    </BasicNavbar>
    <!-- cover -->
    <div class="relative">
      <img src="@/assets/vip-cover.png" alt="封面">
      <div class="absolute left-4 bottom-45px flex items-center space-x-4">
        <div class="border border-#e6e6e6 border-1px w-10 h-10 rounded-full overflow-hidden">
          <img src="@/assets/default-avatar.png" alt="头像">
        </div>
        <div class="text-13px font-bold text-#EDCCA0B2">
          <p>ID: {{ userStore.$state.code }}</p>
          <p v-if="isVip">
            VIP会员有效期至 {{ formatVipExpire }}
          </p>
          <p v-else>
            您还不是VIP会员，开通立享会员特权
          </p>
        </div>
      </div>
    </div>
    <!-- products -->
    <div v-if="productFetching" class="w-full h-50 flex items-center justify-center bg-white">
      <BasicSpinner class="py-10" />
    </div>
    <div v-else class="relative -mt-4 rounded-t-4 py-7 pt-0 bg-white z-10">
      <div class="flex flex-nowrap space-x-2.5 overflow-scroll px-4 pt-6">
        <BizSignProduct
          v-for="(product, index) in products" :key="product.id"
          :product="product" :highlight="index === 0"
          :pay-location="PAY_LOCATION_PAGE.VIP"
          class="shrink-0"
        />
      </div>
      <BizBlockBuyVipCancel class="mt-4 px-4" />
    </div>
    <!-- icons -->
    <div class="px-10 py-7.5 flex flex-col items-center bg-gradient-to-b from-#FFF7EC to-white">
      <img src="@/assets/vip-title.png" class="w-57">
      <img src="@/assets/vip-icons.png" class="mt-7.5 w-full">
    </div>
    <!-- recommend -->
    <div v-if="recommendBook" class="pt-2 px-4 bg-white">
      <div
        class="bg-gradient-to-b from-#FFF7EC to-white relative rounded-2"
        style="border: 1px solid;border-image-source: linear-gradient(180deg, #FDE5C7 0%, #E9E9E9 100%);"
      >
        <img class="w-24 absolute -top-2" src="@/assets/recommend-title.png">
        <div class="pt-30px pb-10px px-4 flex space-x-4" @click="gotoRecommend">
          <img class="w-58px h-82px aspect-9/16 rounded-1" :src="recommendBook.contentCoverUrl">
          <div>
            <p class="text-15px font-bold">
              {{ recommendBook.contentName }}
            </p>
            <p class="mt-5px text-13px text-#999">
              {{ recommendBook.finishStatus ? '已完结' : `已更新至${recommendBook.chapterNum}章` }}
            </p>
            <p class="mt-10px text-15px text-#D3A160 font-bold">
              开通后全本免费读
            </p>
          </div>
        </div>
        <div class="bg-#FEEEDA w-full py-2 rounded-b-2 text-14px text-#794902 font-bold text-center" @click="gotoHome">
          <p>更多精选 ></p>
        </div>
      </div>
    </div>
    <!-- tips -->
    <BizBlockBuyVipTips class="py-8 px-4 bg-white" />
  </div>
</template>
