<script lang="ts" setup>
import { inject, onMounted, ref, watch } from 'vue'
import { useInterval, useScroll } from '@vueuse/core'
import { api } from '@/api'

const chapter = ref<GetReadLastPlayResponse['data']>()
onMounted(async () => {
  try {
    const { data } = await api.get<any, GetReadLastPlayResponse>('/read/last/play')
    chapter.value = data
  }
  catch {} // ignore error
})

const { counter, reset } = useInterval(2000, { controls: true })
const hide = ref(false)
const pageRef = inject<HTMLDivElement>('pageRef')
const { y } = useScroll(pageRef)
watch(() => y.value, () => {
  hide.value = true
  reset()
})
watch(() => counter.value, () => {
  hide.value = false
})
</script>

<template>
  <transition name="slide">
    <div v-if="chapter && !hide" class="w-full px-15px fixed bottom-22 left-0 z-20">
      <div class="p-10px pl-65px flex items-center space-x-4 bg-#F5E9CF rounded-5px relative shadow">
        <div class="p-1 w-60px rounded-2 bg-#F5E9CF absolute left-10px bottom-10px">
          <img class="w-full rounded-3px" :src="chapter.contentCoverUrl" alt="图片:书封">
        </div>
        <div class="flex-1 overflow-hidden">
          <p class="text-15px font-bold truncate">
            {{ chapter.contentName }}
          </p>
          <p class="text-13px text-#666 line-clamp-1">
            已阅读至{{ chapter.contentChapterName }}
          </p>
        </div>
        <BizTrackClick name="继续阅读" type="link">
          <router-link
            :to="{ name: 'chapter', params: { contentId: chapter.contentId, chapterId: chapter.contentChapterId }, query: { scrollY: chapter.sectionMediaPlayedSeconds } }" replace
            class="px-10px py-4px text-12px text-primary font-bold bg-white rounded-full"
          >
            继续阅读
          </router-link>
        </BizTrackClick>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.slide-enter-active {
  transition: all 0.3s ease-in 0.3s;
  transform: translateY(0);
  opacity: 1;
}
.slide-enter {
  opacity: 0;
  transform: translateY(88px);
}
.slide-leave-active {
  transition: all 0.2s ease-out;
  transform: translateY(88px);
}
.slide-leave-to {
  opacity: 0;
}
</style>
