<script setup lang="ts">
import { useRouter } from 'vue-router/composables'

// import { useLocalStorage } from '@vueuse/core'
// import { STORAGE_CUSTOM_THEME } from '@/constants'

// const theme = useLocalStorage(STORAGE_CUSTOM_THEME, {
//   background: '#F5E9CF',
//   size: '42',
// })

const router = useRouter()
function gotoHome() {
  router.replace({ name: 'home' })
}
</script>

<template>
  <div class="min-h-screen">
    <BasicNavbar />

    <div class="py-8 px-4 ">
      <BizBlockEmpty title="没有续看的书" />

      <div class="w-full flex space-x-2 justify-center">
        <button
          class="mt-15 px-4 py-2 text-primary border-primary border-1 border-solid rounded-full"
          @click="gotoHome()"
        >
          去书城看看
        </button>
      </div>
    </div>
  </div>
</template>
