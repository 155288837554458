<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { useHistoryStore } from '@/stores'

const props = defineProps<{ type: string }>()
const router = useRouter()
const historyStore = useHistoryStore()

const chapter = ref<GetReadLastPlayResponse['data']>()
onMounted(async () => {
  if (props.type === 'history') {
    try {
      const { data } = await api.get<any, GetReadLastPlayResponse>('/read/last/play')

      if (!data) {
        router.replace({ name: 'home' })
        return
      }

      chapter.value = data
      router.replace({
        name: 'chapter',
        params: { contentId: chapter.value.contentId, chapterId: chapter.value.contentChapterId },
        query: { scrollY: String(chapter.value.sectionMediaPlayedSeconds ?? '') },
      })
    }
    catch {
      router.replace({ name: 'home' })
    }
    finally {
      historyStore.$clear()
    }
  }
})
</script>

<template>
  <div class="fixed inset-0 flex items-center justify-center">
    <p>页面跳转中...</p>
  </div>
</template>
