<script lang="ts" setup>
import queryString from 'query-string'
import { computed, ref, watch } from 'vue'
import { useConfigStore, useQueryStore, useToastStore, useTrackValueStore, useUserStore } from '@/stores'
import { generateOAuthUrl } from '@/wechat'
import { PAY_LOCATION_PAGE, PRODUCT_TYPE, isIOS } from '@/constants'
import { fen2yuan } from '@/utils'

type Product = GetRechargetemplateGetproductlistResponse['data'][0]

const props = withDefaults(
  defineProps<{
    product: Product
    payLocation?: PAY_LOCATION_PAGE
    contentId?: string
    contentName?: string
    chapterId?: string
    chapterIndex?: number
    rechargeInterception?: Record<string, any>
    skipRechargeInterception?: boolean
    showRechargeAdd?: boolean
    rechargeAdd?: any
    plain?: boolean // 简单样式
  }>(),
  {
    payLocation: PAY_LOCATION_PAGE.NONE,
  },
)

const queryStore = useQueryStore()
const trackValueStore = useTrackValueStore()
const configStore = useConfigStore()
const userStore = useUserStore()
const { $toast } = useToastStore()

const showRechargeInterception = computed(() => {
  if (!props.rechargeInterception?._exist)
    return false

  if (props.rechargeInterception.interceptType === 2)
    return true

  if (props.rechargeInterception.interceptType === 1 && props.product.price >= props.rechargeInterception.minRechargeAmount)
    return true

  return false
})

const rechargeAddProduct = computed(() => {
  if (showRechargeInterception.value)
    return null
  if (!props.rechargeAdd)
    return null
  if (props.product.productType !== PRODUCT_TYPE.COIN)
    return null
  return props.rechargeAdd?.productMap?.[props.product.id || props.product.productId]
})

async function onChoose(product: any) {
  // 充值页
  if (props.payLocation === PAY_LOCATION_PAGE.CHAPTER) {
    window.collectEvent('elementClick', {
      type: '充值遮罩页',
      page: ['', '阅读页', '充值页', '活动'][props.payLocation],
      content_id: props.contentId,
      content_name: props.contentName,
      chapter_index: props.chapterIndex,
    })
  }
  else {
    window.collectEvent('elementClick', {
      type: '充值页',
      page: ['', '阅读页', '充值页', '活动'][props.payLocation],
    })
  }

  const { productId } = product
  await configStore.init({ force: true })
  const payDomain = configStore.$state.payDomain
  const appId = configStore.$state.payAppId
  if (!payDomain || !appId) {
    $toast('支付配置错误，请联系客服')
    return
  }

  let currentUrl = window.location.href
  // Note: 如果是章节付费弹窗添加 `skipPosition`
  if (props.payLocation === PAY_LOCATION_PAGE.CHAPTER && !window.location.href.includes('skipPosition'))
    currentUrl = `${window.location.href}&skipPosition=1`

  const oAuthUrl = generateOAuthUrl({
    appId,
    redirect: `${payDomain}/paying?${queryString.stringify({
      redirect: window.encodeURIComponent(currentUrl),
      callbackUrl: window.encodeURIComponent(currentUrl),
      productId,
      memberId: userStore.$state.memberId,
      appId: queryStore.$state.max_app_id,
      promotionId: queryStore.$state.max_pid,
      paySid: queryStore.$state.max_sid,
      appVersion: __APP_VERSION__,
      OS: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows,
      payLocation: props.payLocation,
      contentId: props.contentId ?? '',
      chapterId: props.chapterId ?? '',
      rechargeOaaId: showRechargeInterception.value ? props.rechargeInterception?.rechargeBlockingActivityId ?? '' : '',
      rechargeStrategyId: showRechargeInterception.value ? '' : props.showRechargeAdd && rechargeAddProduct.value ? props.rechargeAdd?.id ?? '' : '',
      skipRechargeInterception: props.skipRechargeInterception ? '1' : '',
      ...queryStore.$state,
      ...trackValueStore.$state,
    })}`,
  })
  window.location.replace(oAuthUrl)
}

// Note: delay 100ms for number scroll animate
const animatePrice = ref(props.product.price)
watch(() => props.showRechargeAdd, () => {
  setTimeout(() => {
    if (props.showRechargeAdd)
      animatePrice.value = fen2yuan(props.rechargeAdd?.price + props.product.price)
    else
      animatePrice.value = fen2yuan(props.product.price)
  }, 100)
}, { immediate: true })

const formatProductNameChunks = computed(() => {
  let content = props.product.name
  let prefix = ''
  let suffix = ''
  if (props.product.name.startsWith('￥')) {
    prefix = '￥'
    content = content.slice(1)
  }
  else if (props.product.name.endsWith('元')) {
    suffix = '元'
    content = content.slice(0, -1)
  }
  return [prefix, content, suffix]
})

// 如果带小数就显示小数, 如果不带就不显示
const formatRechargeAddFooter = computed(() => {
  const value = ((props.product.coinsBonus + rechargeAddProduct.value?.coinsBonus) / props.rechargeAdd?.exchangeCoins).toFixed(2)
  return value.replace(/0+$/, '').replace(/\.$/, '')
})
</script>

<template>
  <div class="relative">
    <!-- Finger -->
    <img
      v-if="product.pointerState && !plain" class="w-50px absolute top-12 -right-5 z-10"
      src="@/assets/icon-flash-finger.gif" alt="图标:手指"
    >
    <div class="h-93px py-5 bg-#F6F6F6 border border-#E6E6E6 rounded-lg rounded relative overflow-hidden" @click="onChoose(product)">
      <!-- 角标 -->
      <p
        v-if="product.cornerTip && !plain" class="
          absolute top-0 right-0 px-2 py-0.5 text-11px text-#FF6E0F font-bold rounded-lb-lg rounded-tr-7px
          text-white bg-gradient-to-br from-yellow-500 via-orange-500 to-red-500
        "
      >
        {{ product.cornerTip }}
      </p>
      <!-- 商品名称/价格 -->
      <div class="text-22px! font-bold h-30px flex items-center justify-center">
        <p v-if="showRechargeInterception" class="flex items-center justify-center">
          <BasicCountUp
            :start="fen2yuan(product.price)"
            :end="fen2yuan(Math.max(product.price - rechargeInterception?.redPacketAmount, 1))" :decimal="1"
          />
          <span class="ml-2px mt-8px text-12px">元</span>
        </p>
        <p v-else-if="showRechargeAdd && rechargeAddProduct" class="flex items-center justify-center ">
          <BasicCountScrollUp :number="animatePrice" color="#000" />
          <span class="ml-2px mt-8px text-12px">元</span>
        </p>
        <!-- prefix, content, suffix -->
        <p v-else class="w-full text-22px! text-center truncate">
          <span v-if="formatProductNameChunks[0]" class="mt-1 text-12px">{{ formatProductNameChunks[0] }}</span>
          <span>{{ formatProductNameChunks[1] }}</span>
          <span v-if="formatProductNameChunks[2]" class="mt-1 text-12px">{{ formatProductNameChunks[2] }}</span>
        </p>
      </div>
      <!-- 金币 和 底部提示 -->
      <!-- 开启充值加购 -->
      <transition name="slide-left">
        <div v-if="showRechargeAdd && rechargeAddProduct" class="absolute w-full bottom-0 left-0 delay-800!">
          <p v-if="PRODUCT_TYPE.COIN === product.productType" class="text-12px opacity-80 text-center">
            {{ product.coinsBuy + rechargeAddProduct.coinsBuy }}金币
            <span class="text-primary">+{{ product.coinsBonus + rechargeAddProduct.coinsBonus }}</span>
            <span>金币</span>
          </p>
          <p v-else-if="PRODUCT_TYPE.VIP === product.productType" class="text-12px opacity-80 text-center">
            {{ product.description }}
          </p>
          <p
            class="
              w-full py-0.5 mt-6px text-center text-11px text-#EF2E2D font-bold rounded-b-7px
            text-white bg-gradient-to-br from-yellow-500 via-orange-500 to-red-500"
          >
            {{ `多送${formatRechargeAddFooter}元` }}
          </p>
        </div>
      </transition>
      <transition name="slide-right">
        <div v-if="!(showRechargeAdd && rechargeAddProduct)" class="absolute w-full bottom-0 left-0 delay-800!">
          <p v-if="PRODUCT_TYPE.COIN === product.productType" class="text-12px opacity-80 text-center">
            {{ product.coinsBuy }}金币
            <template v-if="product.coinsBonus">
              <span class="text-primary">+{{ product.coinsBonus }}</span>
              <span>金币</span>
            </template>
          </p>
          <p v-else-if="PRODUCT_TYPE.VIP === product.productType" class="text-12px opacity-80 text-center">
            {{ product.description }}
          </p>
          <p
            v-if="product.footTip && !plain"
            class="
              w-full h-20px lh-20px mt-6px text-center text-11px text-#EF2E2D font-bold rounded-b-7px
              text-white bg-gradient-to-br from-yellow-500 via-orange-500 to-red-500
            "
          >
            {{ product.footTip }}
          </p>
          <p v-else class="w-full h-20px lh-20px" />
        </div>
      </transition>
    </div>
  </div>
</template>
