<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { useToastStore } from '@/stores'

const props = defineProps(['contentId', 'chapterId', 'form'])
const { $toast } = useToastStore()
const router = useRouter()

// 表单列表
interface Reason {
  value: number
  label: string
  isSelect?: boolean
}
const reasons = ref<Reason[]>([])
// 联系方式
const contact = ref<string[]>()

const issueValue = ref<string>('')
const contentValue = ref<string>('')
const mobileValue = ref<string>('')
const isDisabled = ref<boolean>(false)

function saveComplaint() {
  const reasonsValue: number[] = reasons.value
    .filter(item => item.isSelect)
    .map(item => item.value)
  if (reasonsValue.length === 0) {
    $toast('请选择投诉类型')
    return
  }
  if (!issueValue.value) {
    $toast('请输入具体原因')
    return
  }

  if (issueValue.value.length > 100) {
    $toast('具体原因不能超过100字')
    return
  }

  if (contentValue.value.length > 100) {
    $toast('投诉内容不能超过100字')
    return
  }

  if (!mobileValue.value.match(/^1\d{10}$/)) {
    $toast('请输入正确的手机号')
    mobileValue.value = ''
    return
  }

  const params: GetMemberFeedbackSaveQuery = {
    contentId: props.contentId,
    chapterId: props.chapterId,
    reason: reasonsValue.join(','),
    reasonRemarks: issueValue.value,
    mobile: mobileValue.value,
    info: contentValue.value,
  }

  api
    .post<any, GetMemberFeedbackSaveResponse>('/member/feedback/save', params)
    .then(async ({ data }) => {
      if (data) {
        await $toast('提交成功')
        reasons.value.forEach((item) => {
          item.isSelect = false
        })
        router.back()
      }
      else {
        $toast('提交失败，请再试一次')
      }
    })
    .catch((err) => {
      $toast(err.msg)
    })
}

function selectReason(index: number) {
  reasons.value[index].isSelect = !reasons.value[index].isSelect
}

onMounted(() => {
  // 加载投诉列表
  api
    .get<any, GetMemberFeedbackConfigResponse>('/member/feedback/config')
    .then(({ data }) => {
      for (const key in data.reasons) {
        reasons.value.push({
          label: data.reasons[key],
          value: Number(key),
          isSelect: false,
        })
      }
      contact.value = data.contact.split(/\n/)
    })
})
</script>

<template>
  <div class="page">
    <BasicNavbar />
    <div class="complaint-box">
      <div class="title-box">
        <span>请选择投诉类型</span>
        <span class="desc orange">（必填 , 支持多选）</span>
      </div>
      <div class="content-box complaint-desc">
        <div
          v-for="(item, index) in reasons"
          :key="item.value"
          class="item"
          @click="selectReason(index)"
        >
          <span :class="{ selected: item.isSelect }" class="radio" />
          <span class="label">{{ item.label }}</span>
        </div>
      </div>
      <div class="title-box">
        <span>原因补充</span>
        <span class="desc orange">（必填）</span>
      </div>
      <div class="content-box">
        <input
          v-model="issueValue"
          class="input-box"
          maxlength="200"
          placeholder="请输入具体原因"
        >
      </div>
      <div class="title-box">
        <span>投诉说明</span>
        <span class="desc">（选填）</span>
      </div>
      <div class="content-box">
        <input
          v-model="contentValue"
          class="input-box"
          maxlength="200"
          placeholder="请输入投诉内容"
        >
      </div>
      <div class="title-box">
        <span>请留下您的联系方式，方便复查</span>
        <span class="desc orange">（必填）</span>
      </div>
      <div class="content-box">
        <input
          v-model="mobileValue"
          class="input-box"
          maxlength="11"
          placeholder="请输入手机号"
          type="number"
        >
      </div>
      <div class="title-box has-second">
        <span>联系客服</span>
      </div>
      <div class="content-box">
        <div class="desc">
          <div v-for="(item, index) in contact" :key="index">
            {{ item }}
          </div>
        </div>
      </div>

      <div class="submit-btn__blank" />
      <div class="submit-btn__bg">
        <div
          :class="{ 'is-disabled': isDisabled }"
          class="submit-btn"
          @click="saveComplaint"
        >
          提交
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.complaint-box {
  font-size: 13px;
  box-sizing: border-box;
  padding: 60px 20px calc(70px + env(safe-area-inset-bottom));
  background: #ffffff;
}

.tip-box {
  font-size: 11px;
  color: #666;
  padding-bottom: 1em;
}

.orange {
  color: #ef2e2d;
}

.title-box {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;

  &.has-second {
    margin-bottom: 10px;
  }

  .desc {
    font-size: 11px;
    font-weight: normal;
  }
}

.content-box {
  margin-bottom: 20px;
  display: flex;

  .desc {
    font-size: 11px;
    margin-bottom: 20px;
    line-height: 2;
  }
}

.complaint-desc {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 100%;
    //line-height: 41px;
    display: flex;
    align-items: flex-start;
    padding: 15px * 0.5 0;
    box-sizing: border-box;
  }

  .radio {
    width: 1.5em;
    height: 1.5em;
    background-size: cover;
    background-image: url("@/assets/icon-radio.png");
    margin-right: 15px * 0.5;
    flex-shrink: 0;

    &.selected {
      background-image: url("@/assets/icon-radio-select.png");
    }
  }
}

.input-box {
  font-size: 13px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 6px 15px;
  line-height: 1;
  width: 100%;

  &:focus,
  &:hover {
    border-color: #ff6e0f;
  }
}

.submit-btn {
  width: 100%;
  height: 50px;
  background: #ff6e0d;
  border-radius: 25px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-bottom: 20px;

  &__bg {
    height: calc(70px + env(safe-area-inset-bottom));
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #ffffff;
    z-index: 10;
  }

  &.is-disabled {
    color: #999999;
    background: #f0f0f0;
  }
}
</style>
