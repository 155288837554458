import Vue from 'vue'
import { PiniaVuePlugin, createPinia } from 'pinia'
import VueRouter from 'vue-router'
import { HeadVuePlugin, createHead } from '@vueuse/head'
import 'uno.css'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import PortalVue from 'portal-vue'
import VConsole from 'vconsole'
import { STORAGE_DEBUG } from './constants'
import router from '@/router'
import App from '@/App.vue'
import { disableAdjustFontSize } from '@/wechat'
import { initTrack } from '@/track'

// Note: current use `px`
// import '@/libs/flexible'

Vue.config.productionTip = false

// Pinia
const pinia = createPinia()
Vue.use(PiniaVuePlugin)

// Head
const head = createHead()
Vue.use(HeadVuePlugin)

// Router
Vue.use(VueRouter)

// Portal
Vue.use(PortalVue)

// Ignore custom elements
Vue.config.ignoredElements = ['wx-open-subscribe']

try {
  // Sentry
  import.meta.env.MODE !== 'development' && Sentry.init({
    environment: import.meta.env.MODE,
    Vue,
    logErrors: true,
    dsn: 'https://8e50f261eace40e484394e068dca6c61@sentry.17k.com/29',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracePropagationTargets: ['localhost', '^\//'],
    tracesSampleRate: 0.05,
  })

  disableAdjustFontSize()
  initTrack()
}
catch {}

if (import.meta.env.MODE !== 'production' || window.localStorage.getItem(STORAGE_DEBUG)) {
  // eslint-disable-next-line no-new
  new VConsole()
}

new Vue({
  router,
  pinia,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  head,
  render: h => h(App),
}).$mount('#app')
