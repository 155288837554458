<script setup lang="ts">
import { PAY_LOCATION_PAGE, STORAGE_SIGN_PRODUCT_ID } from '@/constants'
import { fen2yuan } from '@/utils'
import { useConfigStore, useQueryStore, useTrackValueStore, useUserStore } from '@/stores'
import { $getAppId, $gotoPayProduct } from '@/helpers'
import { api } from '@/api'

type Product = GetRechargetemplateGetproductlistResponse['data'][0]

const props = withDefaults(
  defineProps<{
    product: Product
    highlight?: boolean
    payLocation?: PAY_LOCATION_PAGE
    contentId?: string
    contentName?: string
    chapterId?: string
    chapterIndex?: number
    skipSign?: boolean
  }>(),
  {
    highlight: false,
    payLocation: PAY_LOCATION_PAGE.NONE,
    skipSign: false, // 不需要签约, 直接去支付商品
  },
)

const userStore = useUserStore()
const configStore = useConfigStore()
const queryStore = useQueryStore()
const trackValueStore = useTrackValueStore()

async function onSign() {
  window.collectEvent('pagepurClick', {
    page: 'VIP',
    button: '破冰商品',
    money: fen2yuan(props.product.price),
  })

  const productId = props.product.id || props.product.productId
  // Note: 如果是章节付费弹窗添加 `skipPosition`
  let redirect = window.location.href
  if (props.payLocation === PAY_LOCATION_PAGE.CHAPTER && !window.location.href.includes('skipPosition'))
    redirect = `${window.location.href}&skipPosition=1`

  // 如果不需要签约, 直接去支付商品
  if (props.skipSign) {
    $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: props.payLocation, redirect })
    return
  }

  // Note: check exist sign subscribe?
  const { data: exist } = await api.post('/pay/getContractInfo', {
    productId,
  })

  // 如果存在合约, 直接去支付商品
  if (exist) {
    $gotoPayProduct({ userStore, configStore, queryStore, trackValueStore, productId, payLocation: props.payLocation, redirect })
    return
  }
  const payload = {
    appId: $getAppId(),
    memberId: userStore.$state.memberId,
    productId,
  }
  window.localStorage.setItem(STORAGE_SIGN_PRODUCT_ID, productId)
  const { data } = await api.post<any, PostPayCreatewechatmpcontractResponse>('/pay/createWechatMPContract', payload)
  const subscribeUrl = data?.returnUrl as string
  window.location.href = subscribeUrl
}
</script>

<template>
  <div
    class="w-full h-100px px-30px rounded-3 relative border-1px border-#0000001A border-2px border-#EB514C"
    style="background: linear-gradient(180deg, #FFF6EB 0%, #FBDFB9 100%);"
    @click="onSign"
  >
    <p class="mt-14px text-15px text-center font-bold">
      {{ product.name }}
    </p>
    <p class="font-bold flex items-baseline justify-center text-#794902">
      <span class="text-13px">￥</span>
      <span class="text-30px lh-30px">{{ fen2yuan(product.price) }}</span>
    </p>
    <p
      v-if="product.description"
      class="
        w-full h-19px lh-19px absolute bottom-0 left-0 text-center text-11px rounded-b-7px
        text-white bg-#EB514C
      "
    >
      {{ product.footTip }}
    </p>
    <!-- left corner -->
    <p
      v-if="product.cornerTip"
      class="absolute -top-2 px-2 py-2px text-white text-11px rounded-tl-2 rounded-br-2 z-1"
      :class="highlight
        ? '-left-2px bg-gradient-to-r from-#EB5C4C to-#FE5D5C'
        : '-left-1px bg-gradient-to-r from-#D38B2B to-#F1CA97'"
    >
      {{ product.cornerTip }}
    </p>
  </div>
</template>
