<script lang="ts" setup>
import { onMounted } from 'vue'

const props = withDefaults(
  defineProps<{
    title?: string
  }>(),
  {
    title: '服务器错误',
  },
)

onMounted(() => {
  window.collectEvent('onError', {
    error_title: props.title,
    err_msg: props.title,
    error_type: 'page',
    err_code: 'page_error',
  })
})
</script>

<template>
  <div class="py-20 w-50 mx-a">
    <img class="w-full" src="@/assets/cover-error.png">
    <p class="mt-14px text-center text-#666 text-13px">
      {{ title }}
    </p>
  </div>
</template>
