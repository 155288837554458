<script lang="ts" setup>
import { computed, onMounted, ref, set } from 'vue'
import { useRouter } from 'vue-router/composables'
import { TASK_STATE, TASK_TYPE } from '@/constants'
import { api } from '@/api'
import { useConfigStore, useLoadingStore, useToastStore, useUserStore } from '@/stores'
import { sleep } from '@/utils'

const { $toast } = useToastStore()
const { $showLoading, $hideLoading } = useLoadingStore()
const userStore = useUserStore()

const router = useRouter()

const signed = ref(true)
const progress = ref<GetTaskMemberProgressBarResponse['data']['memberSignResponseList']>([])
const modalSigned = ref(false)
const modalSignedCoin = ref<number>()
async function fetchProgress() {
  try {
    const { data } = await api.get<any, GetTaskMemberProgressBarResponse>('/task/member/progress-bar')
    signed.value = data.isSign
    progress.value = data.memberSignResponseList
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || '获取签到进度失败')
  }
}
onMounted(async () => {
  fetchProgress()
})
async function onSign() {
  if (signed.value) {
    router.replace({ name: 'home' })
    return
  }
  try {
    $showLoading()
    const { data } = await api.get<any, GetTaskMemberSignResponse>('/task/member/sign')
    // Note: open modal
    modalSigned.value = true
    modalSignedCoin.value = data.prizeNum

    // Note: 手动更新, 更新进度列表
    signed.value = true
    for (let index = 0; index < data.num; index++)
      set(progress.value[index], 'signFlag', 1)

    // Note: 手动更新金币
    userStore.$state.coinBalance += data.prizeNum
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || '签到失败')
  }
  finally {
    $hideLoading()
  }
}

const tasks = ref<GetTaskMemberListResponse['data']>([])
onMounted(async () => {
  try {
    const { data } = await api.get<any, GetTaskMemberListResponse>('/task/member/list')
    tasks.value = data
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg)
  }
})
async function onTask(task: any) {
  // Note: 阅读任务需要判断
  if (task.finishCount < task.totalCount) {
    router.replace({ path: task.completeLink })
    return
  }

  try {
    const { id } = task
    const { msg } = await api.get<any, GetTaskMemberReceiveResponse>('/task/member/receive', { params: { id } })
    $toast('奖励领取成功')
    // Note: 手动更新状态
    userStore.$state.coinBalance += task.prizeNum
    set(task, 'state', TASK_STATE.FINISHED)
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || JSON.stringify(error))
  }
}

const configStore = useConfigStore()
const wxConfigSuccess = ref(true)
const templateIds = computed(() => {
  return configStore.$state.subscribeTemplateList
})
onMounted(async () => {
  try {
    const url = window.entryUrl || window.location.href
    const { data } = await api.post<any, PostWxMpSignjsapiResponse>('/wx/mp/signJsApi', { jsApiUrl: url })
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp,
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [],
      openTagList: ['wx-open-subscribe'],
    })
    wx.error((res: any) => {
      wxConfigSuccess.value = false
      console.error('wx.config 签名失败', { res })
      // TODO: report
    })
  }
  catch (error) {
    console.error(error)
  }
})
const showWxSubscribe = computed(() => {
  return wxConfigSuccess.value && templateIds.value.length > 0
})
async function onSubscribeSuccess(task: any, detail: any) {
  const templateIds = []
  const templates = JSON.parse(detail.subscribeDetails)
  for (const [id, template] of Object.entries<any>(templates)) {
    if (JSON.parse(template).status === 'accept')
      templateIds.push(id)
  }
  if (!templateIds.length) {
    $toast('订阅失败!\n请到公众号设置中开启推送权限。', 3000)
    return
  }
  await api.post<any, any>('/template/subscribe', { templateIds })
  await sleep(1500)
  set(task, 'state', TASK_STATE.WAIT_RECEIVE)
  set(task, 'finishCount', task.finishCount + 1)
  $toast('订阅成功')
}
function onSubscribeFail(task: any, detail: any) {
  // eslint-disable-next-line no-console
  console.log(task.detail)
  $toast('订阅失败!\n请到公众号设置中开启推送权限。', 3000)
}
</script>

<template>
  <div class="min-h-screen pb-10 bg-#F7E8D4">
    <!-- Cover -->
    <div class="cover w-full h-186px relative">
      <BasicNavbar />

      <div
        class="w-114px h-41px px-5px flex space-x-2 items-center bg-white rounded-full absolute left-50px top-85px"
        style="background: linear-gradient(89.88deg, #FFFFFF 0.52%, rgba(255, 255, 255, 0.4) 99.89%);"
      >
        <img class="-mt-2 w-8" src="@/assets/icon-coin-logo.png" alt="图标:金币Logo">
        <div>
          <p class="text-10px">
            我的金币
          </p>
          <!-- TODO: Add number animate? -->
          <p class="text-18px lh-none">
            {{ userStore.$state.coinBalance }}
          </p>
        </div>
      </div>
    </div>
    <div class="px-5 -mt-10 relative">
      <!-- 签到 -->
      <div class="p-15px bg-white rounded-lg">
        <div class="flex items-center justify-between">
          <p class="text-17px font-bold">
            签到赚金币
          </p>
          <p class="text-13px text-#999">
            连续签到可获得大量金币
          </p>
        </div>
        <div class="line my-15px h-2px relative">
          <img src="@/assets/welfare-line.png" alt="图片:线">
        </div>
        <div class="grid grid-cols-7 gap-5px">
          <div v-for="item in progress" :key="item.num">
            <div
              class="pt-5px pb-3px flex flex-col items-center bg-#F6F6F6 rounded"
              :class="{ 'bg-#FF7D0033': item.signFlag }"
            >
              <img v-if="item.signFlag" class="w-6" src="@/assets/icon-coin-disabled.png" alt="图标:金币">
              <img v-else class="w-6" src="@/assets/icon-coin.png" alt="图标:金币">
              <p class="mt-1 text-10px text-#666">
                +{{ item.prizeNum }}
              </p>
            </div>
            <p class="mt-2 text-10px text-#666 text-center">
              第{{ item.num }}天
            </p>
          </div>
        </div>
        <div class="mt-15px flex justify-center">
          <button
            class="button block w-50 h-42px lh-42px text-15px text-white text-center font-bold rounded-full"
            @click="onSign"
          >
            {{ signed ? '签到完成，继续看书' : '立即签到，领取金币' }}
          </button>
        </div>
      </div>
      <!-- 任务 -->
      <div v-if="tasks.length" class="mt-15px p-15px pb-0 bg-white rounded-lg">
        <div class="flex items-center justify-between">
          <p class="text-17px font-bold">
            做任务领金币
          </p>
          <p class="text-13px text-#999">
            每日重置
          </p>
        </div>
        <div
          v-for="(task, index) in tasks" :key="task.id"
          :class="{ 'border-t': index !== 0 }"
          class="py-15px flex items-center justify-between"
        >
          <template v-if="task.detailType === TASK_TYPE.SUBSCRIBE && [TASK_STATE.INITIAL, TASK_STATE.WAIT_FINISHE].includes(task.state)">
            <div v-if="showWxSubscribe" class="w-full flex items-center justify-between">
              <div>
                <div class="flex items-center space-x-5px">
                  <p>
                    <span class="text-15px font-bold">{{ task.taskName }} </span>
                    <span v-if="task.totalCount > 1">({{ task.finishCount }}/{{ task.totalCount }})</span>
                  </p>
                  <img class="w-4" src="@/assets/icon-coin.png" alt="图标:金币">
                  <span class="text-12px py-0.5 px-1 text-#FF7D00 bg-#FFF7E8 rounded">+{{ task.prizeNum }}金币</span>
                </div>
                <p class="mt-5px text-12px text-#999">
                  {{ task.description }}
                </p>
              </div>
              <BasicWxOpenSubscribe
                @success="detail => onSubscribeSuccess(task, detail)"
                @fail="detail => onSubscribeFail(task, detail)"
              />
            </div>
          </template>
          <template v-else>
            <div>
              <div class="flex items-center space-x-5px">
                <p>
                  <span class="text-15px font-bold">{{ task.taskName }} </span>
                  <span v-if="task.totalCount > 1">({{ task.finishCount }}/{{ task.totalCount }})</span>
                </p>
                <img class="w-4" src="@/assets/icon-coin.png" alt="图标:金币">
                <span class="text-12px py-0.5 px-1 text-#FF7D00 bg-#FFF7E8 rounded whitespace-nowrap">+{{ task.prizeNum }}金币</span>
              </div>
              <p class="mt-5px text-12px text-#999">
                {{ task.description }}
              </p>
            </div>
            <a
              v-if="task.state === TASK_STATE.FINISHED"
              class="block w-74px h-30px lh-30px text-13px text-#999 text-center bg-#F0F0F0 rounded-full"
            >
              已完成
            </a>
            <a
              v-else
              class="block w-74px h-30px lh-30px text-13px text-white text-center rounded-full"
              style="background: linear-gradient(90deg, #FF6E0F 0%, #FF5970 100%);"
              @click="onTask(task)"
            >
              {{ task.state === TASK_STATE.WAIT_RECEIVE ? '立即领取' : task.buttonTitle }}
            </a>
          </template>
        </div>
      </div>
    </div>

    <BasicModal v-model="modalSigned">
      <div class="p-5">
        <div class="text-17px lh-2em font-bold text-center">
          <p>恭喜获得 {{ modalSignedCoin }} 金币</p>
          <p>明天签到奖励更多</p>
        </div>
        <a
          class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
          @click="modalSigned = false"
        >
          知道了
        </a>
      </div>
    </BasicModal>
  </div>
</template>

<style scoped>
.cover {
  background: url('@/assets/cover-welfare.png');
  background-size: 100% 100%;
}
.line::before,
.line::after {
  content: '';
  display: block;
  width: 13px;
  height: 13px;
  background: #F7E8D4;
  position: absolute;
  border-radius: 99px;
}
.line::before {
  left: -21px;
  top: -6px;
}
.line::after {
  right: -21px;
  top: -6px;
}
.button {
  background: url('@/assets/background-sign-button.png');
  background-size: 100% 100%;
}
</style>
